import React from "react";
import { allActions } from "../../Redux/myActions";
import { useDispatch, connect } from "react-redux";
import { useEffect } from "react";
import { Button, Divider, Table, Tag, message } from "antd";
import { UserOutlined, HomeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import UpdateAdStatus from "../modals/UpdateAdStatus";
import { useState } from "react";

const mapStateToProps = (store) => {
  const { PropertyReducer } = store;
  return { PropertyReducer };
};

const LatestProperty = ({ PropertyReducer }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allPropertyData, loading, realEstateData } = PropertyReducer;
  const [openAdStatus, setOpenAdStatus] = useState(false);
  const [postData, setPostData] = useState("");

  const handleNavigateToProperty = () => {
    localStorage.removeItem("sortValue");
    setTimeout(() => {
      navigate("/my-property/all");
    }, 1000);
  };

  const columns = [
    {
      title: "Photo",
      dataIndex: "thumbnail",
      align: "center",
      width: 180,
      render: (cat_image) => {
        return cat_image ? (
          <img
            alt=""
            style={{
              height: "auto",
              width: "100%",
              objectFit: "cover",
              maxHeight: "7rem",
              border: "1px solid gray",
              background: "white",
            }}
            src={cat_image}
          />
        ) : (
          <HomeOutlined
            style={{
              fontSize: "3rem",
              background: "#808080",
              color: "white",
              padding: "0.5rem",
            }}
          />
        );
      },
    },
    {
      title: "Property Type",
      dataIndex: "property_type",
      align: "center",
      render: (property_type) => (
        <p className="capitalize-first-letter">
          {property_type && property_type}
        </p>
      ),
    },
    {
      title: "Title",
      width: 200,
      align: "center",
      dataIndex: "title",
      render: (title) => (
        <p className="capitalize-first-letter">{title && title}</p>
      ),
    },
    {
      title: "Category",
      align: "center",
      dataIndex: "catname",
      render: (catname) => (
        <p className="capitalize-first-letter">{catname && catname}</p>
      ),
    },

    {
      title: "Ad Status",
      align: "center",
      render: (_, data) => {
        return (
          <Tag
            onClick={() => handleSetPostData(data)}
            style={{ cursor: "pointer" }}
          >
            {data?.statusname}
          </Tag>
        );
      },
    },
    {
      title: "Price",
      align: "center",
      render: (data) => {
        return (
          data?.price !== "0" && (
            <div>
              $ {data?.price}{" "}
              {data?.to_price !== "0" && data?.price !== data?.to_price && (
                <span> - ${data?.to_price} </span>
              )}{" "}
            </div>
          )
        );
      },
    },
    {
      title: "Location",
      align: "center",
      dataIndex: "ad_address",
      width: 200,
      render: (address) => {
        return <p>{address}</p>;
      },
    },
    {
      title: "Date",
      align: "center",
      dataIndex: "posted_date",
      render: (posted_date) => {
        return <p>{posted_date ? posted_date : "NaN"}</p>;
      },
    },
    {
      title: "Enquiry",
      align: "center",
      fixed: "right",
      render: (data) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{
                height: "1.5rem",
                width: "1.5rem",
                borderRadius: "3px",
                background: "gray",
                color: "white",
                display: "flex",
                alignSelf: "center",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                Number(data?.enquiry_number) > 0
                  ? handleNavigateOfferPage(data)
                  : message.warning("No Post Offer Message");
              }}
            >
              {data?.enquiry_number}
            </span>
          </div>
        );
      },
    },
  ];

  const handleNavigateOfferPage = (data) => {
    const dataValue = { value: data?.post_id };
    navigate("/offer-message/offer-received", { state: dataValue });
  };

  const handleSetPostData = (postData) => {
    setPostData(postData);
    setOpenAdStatus((prev) => !prev);
  };

  return (
    <div className="latest--property--container">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1rem",
        }}
      >
        <h3>Latest Property</h3>
        <Button
          onClick={handleNavigateToProperty}
          className="button--modal--primary"
        >
          View All
        </Button>
      </div>
      <Divider style={{ margin: "0 0 1rem", padding: "0 1rem" }} />
      <Table
        scroll={{ y: 400, x: 1200 }}
        style={{ margin: "0 1rem 1rem" }}
        pagination={false}
        columns={columns}
        bordered
        dataSource={allPropertyData?.posts}
      />
    </div>
  );
};

export default connect(mapStateToProps)(LatestProperty);
