import { message } from "antd";

const INIT_STATE = {
  loading: false,
  deactivateloading: false,
  changetodoloading: false,
  privacyloading: false,
  logoLoading: false,
  profileLoading: false,
  activeuserloading: false,
  activeuserchatloading: false,
  notificationloading: false,
  seennotificationloading: false,
  profileloading: false,
  todoList: [],
  // profileData: { users: "" },
  profileData: "",
  profileImage: "",
  companyImage: "",
  privacySettingList: {},
  daectivateReasonList: [],
  myActivity: [],
  offerMessageData: {},
  offerUserList: {},
  activeUserChatList: [],
  notificationList: [],
};

export const ProfileReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    //reset state
    case "RESET_PROFILE_STATE": {
      return {
        ...state,
        loading: false,
      };
    }

    //profile--section
    case "PROFILE_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "PROFILE_REQUEST_SUCCESS": {
      localStorage?.setItem("profileImage", action?.payload?.users?.image);
      localStorage?.setItem("realestatedata", JSON.stringify(action?.payload));
      return {
        ...state,
        loading: false,
        profileData: action?.payload,
        profileImage: action?.payload?.users?.image,
        companyImage: action?.payload?.users?.company_logo,
      };
    }
    case "PROFILE_REQUEST_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    //google login
    case "GOOGLE_LOGIN_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "GOOGLE_LOGIN_REQUEST_SUCCESS": {
      return {
        ...state,
        loading: false,
      };
    }

    case "GOOGLE_LOGIN_REQUEST_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    //profile--image--upload
    case "PROFILE_UPLOAD_REQUEST_ATTEMPT": {
      return {
        ...state,
        profileLoading: true,
      };
    }

    case "PROFILE_UPLOAD_REQUEST_SUCCESS": {
      message.success(action?.payload?.message);
      return {
        ...state,
        profileLoading: false,
        profileImage: action?.payload?.source?.image_name,
      };
    }
    case "PROFILE_UPLOAD_REQUEST_FAILURE": {
      message.error(action?.payload?.data?.message);
      return {
        ...state,
        profileLoading: false,
      };
    }

    //company--logo--upload
    case "CLOGO_UPLOAD_REQUEST_ATTEMPT": {
      return {
        ...state,
        // loading: true,
        logoLoading: true,
      };
    }

    case "CLOGO_UPLOAD_REQUEST_SUCCESS": {
      message.success(action?.payload?.message);
      return {
        ...state,
        // loading: false,
        logoLoading: false,
        companyImage: action?.payload?.source?.image_name,
      };
    }
    case "CLOGO_UPLOAD_REQUEST_FAILURE": {
      message.error(action?.payload?.data?.message);
      return {
        ...state,
        // loading: false,
        loading: false,
      };
    }

    //company--logo--upload
    case "UPDATE_PROFILE_DETAIL_REQUEST_ATTEMPT": {
      return {
        ...state,
        profileloading: true,
      };
    }

    case "UPDATE_PROFILE_DETAIL_REQUEST_SUCCESS": {
      message.success(action?.payload?.message);
      return {
        ...state,
        profileloading: false,
        profileData: action?.payload?.users,
        profileImage: action?.payload?.users?.image,
      };
    }
    case "UPDATE_PROFILE_DETAIL_REQUEST_FAILURE": {
      Object.entries(action?.payload?.data?.errors)?.forEach(([key, value]) => {
        message.error(value);
      });
      return {
        ...state,
        profileloading: false,
      };
    }

    //todo--section
    case "ADD_TODO_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "ADD_TODO_REQUEST_SUCCESS": {
      message.success(action?.payload?.message);
      return {
        ...state,
        loading: false,
      };
    }
    case "ADD_TODO_REQUEST_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    //fetch todo list
    case "FETCH_TODOLIST_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "FETCH_TODOLIST_REQUEST_SUCCESS": {
      return {
        ...state,
        loading: false,
        todoList: action?.payload,
      };
    }
    case "FETCH_TODOLIST_REQUEST_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    //delete todo list
    case "DELETE_TODOLIST_REQUEST_ATTEMPT": {
      return {
        ...state,
        changetodoloading: true,
      };
    }

    case "DELETE_TODOLIST_REQUEST_SUCCESS": {
      message.success(action?.payload?.message);
      return {
        ...state,
        changetodoloading: false,
      };
    }

    case "DELETE_TODOLIST_REQUEST_FAILURE": {
      message.error(action?.payload?.message);
      return {
        ...state,
        changetodoloading: false,
      };
    }

    // update elements of todolist
    case "UPDATE_TODOLIST_REQUEST_ATTEMPT": {
      return {
        ...state,
      };
    }

    case "UPDATE_TODOLIST_REQUEST_SUCCESS": {
      message.success(action?.payload?.message);
      return {
        ...state,
      };
    }

    case "UPDATE_TODOLIST_REQUEST_FAILURE": {
      message.error(action?.payload?.message);
      return {
        ...state,
      };
    }

    //update todo list
    case "UPDATE_TODO_LIST": {
      return {
        ...state,
        todoList: action?.payload,
      };
    }

    //get privacy setting data
    case "GET_PRIVACY_SETTING_REQUEST_ATTEMPT": {
      return {
        ...state,
        privacyloading: true,
      };
    }

    case "GET_PRIVACY_SETTING_REQUEST_SUCCESS": {
      return {
        ...state,
        privacyloading: false,
        privacySettingList: action?.payload,
      };
    }

    case "GET_PRIVACY_SETTING_REQUEST_FAILURE": {
      return {
        ...state,
        privacyloading: false,
      };
    }

    //get deactivatereason
    case "GET_DEACTIVATE_REASON_REQUEST_ATTEMPT": {
      return {
        ...state,
        deactivateloading: true,
      };
    }

    case "GET_DEACTIVATE_REASON_REQUEST_SUCCESS": {
      return {
        ...state,
        deactivateloading: false,
        daectivateReasonList: action?.payload,
      };
    }

    case "GET_DEACTIVATE_REASON_REQUEST_FAILURE": {
      return {
        ...state,
        deactivateloading: false,
      };
    }

    //post deactivation reason
    case "POST_DEACTIVATE_REASON_REQUEST_ATTEMPT": {
      return {
        ...state,
        deactivateloading: true,
        loading: true,
      };
    }

    case "POST_DEACTIVATE_REASON_REQUEST_SUCCESS": {
      message.success(action?.payload?.message);
      return {
        ...state,
        deactivateloading: false,
        loading: false,
      };
    }
    case "POST_DEACTIVATE_REASON_REQUEST_FAILURE": {
      message.error(action?.payload?.message);
      return {
        ...state,
        deactivateloading: false,
        loading: false,
      };
    }

    // post privacy setting
    case "POST_PRIVACY_SETTING_REQUEST_ATTEMPT": {
      return {
        ...state,
        privacyloading: true,
        loading: true,
      };
    }

    case "POST_PRIVACY_SETTING_REQUEST_SUCCESS": {
      message.success(action?.payload?.message);
      return {
        ...state,
        privacyloading: false,
        loading: false,
      };
    }
    case "POST_PRIVACY_SETTING_REQUEST_FAILURE": {
      message.error(action?.payload?.message);
      return {
        ...state,
        privacyloading: false,
        loading: false,
      };
    }

    case "FETCH_MYACTIVITY_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "FETCH_MYACTIVITY_REQUEST_SUCCESS": {
      return {
        ...state,
        loading: false,
        myActivity: action?.payload,
      };
    }

    case "FETCH_MYACTIVITY_REQUEST_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    //offer--message
    case "FETCH_OFFER_MESSAGE_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "FETCH_OFFER_MESSAGE_REQUEST_SUCCESS": {
      return {
        ...state,
        loading: false,
        offerMessageData: action?.payload,
      };
    }

    case "FETCH_OFFER_MESSAGE_REQUEST_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    //offer user-list
    case "FETCH_OFFERMESSAGE_USER_REQUEST_ATTEMPT": {
      return {
        ...state,
        activeuserloading: true,
      };
    }

    case "FETCH_OFFERMESSAGE_USER_REQUEST_SUCCESS": {
      return {
        ...state,
        offerUserList: action?.payload,
        activeuserloading: false,
      };
    }
    case "FETCH_OFFERMESSAGE_USER_REQUEST_FAILURE": {
      return {
        ...state,
        activeuserloading: false,
      };
    }

    case "FETCH_ACTIVE_USER_CHAT_REQUEST_ATTEMPT": {
      return {
        ...state,
        activeuserchatloading: true,
      };
    }

    case "FETCH_ACTIVE_USER_CHAT_REQUEST_SUCCESS": {
      const tempChatList = [...action?.payload].reverse();
      tempChatList.splice(0, 0, tempChatList.pop());
      return {
        ...state,
        // activeUserChatList: action?.payload,
        activeUserChatList: tempChatList,
        activeuserchatloading: false,
      };
    }
    case "FETCH_ACTIVE_USER_CHAT_REQUEST_FAILURE": {
      return {
        ...state,
        activeuserchatloading: false,
      };
    }

    case "POST_OFFER_MESSAGE_REQUEST_ATTEMPT": {
      return {
        ...state,
        sendmessageloading: true,
      };
    }

    case "POST_OFFER_MESSAGE_REQUEST_SUCCESS": {
      return {
        ...state,
        sendmessageloading: false,
      };
    }

    case "POST_OFFER_MESSAGE_REQUEST_FAILURE": {
      return {
        ...state,
        sendmessageloading: false,
      };
    }

    case "POST_OFFER_FILE_REQUEST_ATTEMPT": {
      return {
        ...state,
        sendmessageloading: true,
        inquirychatloading: true,
      };
    }

    case "POST_OFFER_FILE_REQUEST_SUCCESS": {
      return {
        ...state,
        sendmessageloading: false,
        inquirychatloading: false,
      };
    }

    case "POST_OFFER_FILE_REQUEST_FAILURE": {
      message.error(action?.payload?.data?.message);
      return {
        ...state,
        sendmessageloading: false,
        inquirychatloading: false,
      };
    }

    //get notification
    case "FETCH_NOTIFICATION_REQUEST_ATTEMPT": {
      return {
        ...state,
        notificationloading: true,
      };
    }

    case "FETCH_NOTIFICATION_REQUEST_SUCCESS": {
      return {
        ...state,
        notificationloading: false,
        notificationList: action?.payload,
      };
    }

    case "FETCH_NOTIFICATION_REQUEST_FAILURE": {
      return {
        ...state,
        notificationloading: false,
      };
    }

    case "SEEN_NOTIFICATION_REQUEST_ATTEMPT": {
      return {
        ...state,
        seennotificationloading: true,
      };
    }
    case "SEEN_NOTIFICATION_REQUEST_SUCCESS": {
      return {
        ...state,
        seennotificationloading: false,
      };
    }

    case "SEEN_NOTIFICATION_REQUEST_FAILURE": {
      return {
        ...state,
        seennotificationloading: false,
      };
    }

    default:
      return state;
  }
};
