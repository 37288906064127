import React from "react";
import { useEffect, useState } from "react";
import { ReactReduxContext, connect, useDispatch } from "react-redux";
import { TimePicker, DatePicker, Divider, InputNumber, Steps } from "antd";
import { Button, Checkbox, Form, Input } from "antd";
import { Radio, Select, Space } from "antd";
import { usePlacesWidget } from "react-google-autocomplete";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

import CurrentScreenSize from "../screenSizeReader/CurrentScreenSize";
import moment from "moment";
import { allActions } from "../../Redux/myActions";

const PostAnForm1 = ({
  static_fields,
  form,
  setFormState,
  fields,
  setLocation,
}) => {
  const isLarge = CurrentScreenSize();
  const dispatch = useDispatch();
  const CheckboxGroup = Checkbox.Group;
  const { TextArea } = Input;
  const [isPriceChecked, setIsPriceChecked] = useState("false");
  const [isPriceType, setIsPriceType] = useState("");
  const [unitSuite, setUnitSuite] = useState("");
  const [propertyLocationList, setPropertyLocationList] = useState([]);
  const [houseAreaType, setHouseAreaType] = useState("");
  const [landAreaType, setLandAreaType] = useState("");

  const fetchLoactionList = (address) => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: `/address_new?address=${address}`,
          attempt: "FETCH_ADDRESS_LIST_REQUEST_ATTEMPT",
          success: "FETCH_ADDRESS_LIST_REQUEST_SUCCESS",
          failure: "FETCH_ADDRESS_LIST_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: (res) => {
            setPropertyLocationList(
              res?.map((item) => {
                return {
                  label: item?.name,
                  value: item?.name,
                };
              })
            );
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU",

    onPlaceSelected: (place) => {
      form.setFieldsValue({ address: place?.formatted_address });

      place?.address_components?.map((item) => {
        if (item.types.includes("street_number")) {
          form.setFieldsValue({
            property_house_number: item?.long_name,
          });
        } else if (item.types.includes("subpremise")) {
          setUnitSuite(item?.long_name);
        } else if (item.types.includes("route")) {
          form.setFieldsValue({
            property_street_name: item?.long_name,
          });
        } else if (item.types.includes("postal_code")) {
          form.setFieldsValue({
            property_postcode: item?.long_name,
          });
        } else if (item.types.includes("locality")) {
          form.setFieldsValue({
            property_district: item?.long_name,
          });
        } else if (item.types.includes("administrative_area_level_1")) {
          form.setFieldsValue({
            property_state: item?.short_name,
          });
          fetchLoactionList(item?.short_name);
        }
        return null;
      });
    },

    options: {
      types: ["address"],
      componentRestrictions: { country: "np" },
    },
  });

  const handleShowPriceChange = (e) => {
    const checked = e.target.checked;
    const result = e.target.value;
    setIsPriceChecked(result);

    if (!checked) {
      form.setFieldsValue({ price_text: undefined });
    }
  };

  const handleChangePriceType = (e) => {
    setIsPriceType(e?.target?.value);
  };

  return (
    <>
      <div style={{ marginTop: "1rem" }} className="two--row--container">
        <Form.Item
          className="two--row--child--container"
          label="Property Address"
          name="address"
        >
          <input
            ref={ref}
            style={{
              width: "100%",
              height: "2.5rem",
              outline: "none",
              borderRadius: "0.5rem",
              paddingLeft: "0.5rem",
              border: "1px solid  #c0c2c980",
            }}
            placeholder="Search Property location"
          />
        </Form.Item>

        <Form.Item
          className="two--row--child--container"
          label="Property postcode"
          name="property_postcode"
          rules={[
            {
              required: true,
              message: "Property postcode is required",
            },
          ]}
        >
          <InputNumber
            size="large"
            placeholder="Property postcode"
            style={{ width: "100%" }}
          />
        </Form.Item>
      </div>
      <div className="two--row--container">
        <Form.Item
          className="two--row--child--container"
          label="Property State"
          name="property_state"
          rules={[
            {
              required: true,
              message: "Property state is required",
            },
          ]}
        >
          <Input size="large" placeholder="Property State" />
        </Form.Item>
        <Form.Item
          className="two--row--child--container"
          label="Property Location"
          name="property_location"
        >
          <Select
            size="large"
            showSearch
            placeholder="Select Property Location"
            options={propertyLocationList}
          />
        </Form.Item>
      </div>
      <div className="two--row--container">
        <Form.Item
          className="two--row--child--container"
          label="Property District"
          name="property_district"
          rules={[
            {
              required: true,
              message: "Property District is required",
            },
          ]}
        >
          <Input size="large" placeholder="Property District" />
        </Form.Item>
        <Form.Item
          className="two--row--child--container"
          label="Property Street Name"
          name="property_street_name"
          rules={[
            {
              required: true,
              message: "Property street name is required",
            },
          ]}
        >
          <Input size="large" placeholder="Property Street Name" />
        </Form.Item>
      </div>

      <h4>Show Detail</h4>
      <div style={{ marginTop: "1rem" }} className="two--row--container">
        {static_fields?.map((item) => {
          if (item?.attr?.label === "Property condition") {
            return (
              <Form.Item
                name={item?.attr?.name}
                label={item?.attr?.label}
                rules={[
                  {
                    required: item?.is_required,
                    message: "Select display value",
                  },
                ]}
                className="two--row--child--container"
                labelAlign="left"
              >
                <Radio.Group>
                  {item?.childs?.map((itm) => (
                    <Radio size="large" value={itm?.value}>
                      {itm?.label}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            );
          }
        })}
        {static_fields?.map((item) => {
          if (item?.attr?.label === "Sell type") {
            return (
              <Form.Item
                className="two--row--child--container"
                rules={[
                  {
                    required: item?.is_required,
                    message: "Sell type is required",
                  },
                ]}
                label={item?.attr?.label}
                name={item?.attr?.name}
              >
                <Select
                  size="large"
                  placeholder="Select sell type"
                  options={[
                    ...item?.childs?.map((itm) => {
                      return {
                        value: itm?.value,
                        label: itm?.label,
                      };
                    }),
                  ]}
                ></Select>
              </Form.Item>
            );
          }
        })}
      </div>

      {static_fields?.map((item) => {
        if (item?.attr?.label === "Price Type") {
          return (
            <Form.Item
              name={item?.attr?.name}
              label={item?.attr?.label}
              rules={[
                {
                  required: item?.is_required,
                  message: "Sell type is required",
                },
              ]}
              onChange={handleChangePriceType}
            >
              <Radio.Group>
                {item?.childs?.map((itm, index) => (
                  <Radio value={itm?.value}>{itm?.label}</Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          );
        }
        return null;
      })}
      <Space
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "0.2rem",
          alignItems: "center",
        }}
      >
        {(isPriceType === "1" || isPriceType === "2") &&
          static_fields?.map((item) => {
            if (item?.attr?.label === "From Price") {
              return (
                <Form.Item
                  name={item?.attr?.name}
                  rules={[
                    {
                      required: item?.is_required,
                      message: "Price is required",
                    },
                  ]}
                >
                  <InputNumber
                    size="large"
                    defaultValue={0}
                    placeholder={isPriceType === "1" ? "Price" : "From Price"}
                    addonAfter="NR"
                  />
                </Form.Item>
              );
            }
          })}
        {isPriceType === "2" &&
          static_fields?.map((item) => {
            if (item?.attr?.label === "To Price") {
              return (
                <Form.Item
                  name={item?.attr?.name}
                  rules={[
                    {
                      required: item?.is_required,
                      message: "To Price is required",
                    },
                  ]}
                >
                  <InputNumber
                    size="large"
                    addonAfter="NR"
                    defaultValue={0}
                    placeholder="To Price"
                  />
                </Form.Item>
              );
            }
          })}
        {(isPriceType === "1" || isPriceType === "2") &&
          static_fields?.map((item) => {
            if (item?.attr?.name === "price_type2") {
              return (
                <Form.Item
                  rules={[
                    {
                      required: item?.is_required,
                      message: "Payment Duration Type is required",
                    },
                  ]}
                  name={item?.attr?.name}
                >
                  <Select
                    size="large"
                    placeholder="Select Price Duration"
                    options={[
                      ...item?.childs?.map((item) => {
                        return {
                          label: item?.label,
                          value: item?.value,
                        };
                      }),
                    ]}
                  ></Select>
                </Form.Item>
              );
            }
            return null;
          })}
      </Space>

      <div style={{ marginTop: "1rem" }} className="two--row--container">
        {static_fields?.map((item) => {
          if (item?.attr?.name === "show_price") {
            return (
              <Form.Item
                name={item?.attr?.name}
                className="two--row--child--container"
                label={item?.attr?.name}
                rules={[
                  {
                    required: item?.is_required,
                    message: "Show price is required",
                  },
                ]}
                onChange={handleShowPriceChange}
              >
                <Radio.Group
                  size="large"
                  options={[
                    ...item?.childs?.map((item) => {
                      return {
                        label: item?.label,
                        value: item?.value,
                      };
                    }),
                  ]}
                ></Radio.Group>
              </Form.Item>
            );
          }
        })}
        {static_fields?.map((item) => {
          if (item?.attr?.name === "security_bound") {
            return (
              <Form.Item
                className="two--row--child--container"
                label={item?.attr?.label}
                name={item?.attr?.name}
              >
                <Input size="large" placeholder="Security Bond" />
              </Form.Item>
            );
          }
          return null;
        })}
      </div>

      <div className="two--row--container">
        {static_fields?.map((item) => {
          if (item?.attr?.label === "Property type") {
            return (
              <Form.Item
                className="two--row--child--container"
                rules={[
                  {
                    required: item?.is_required,
                    message: "Property type is required",
                  },
                ]}
                label={item?.attr?.label}
                name={item?.attr?.name}
              >
                <Select
                  size="large"
                  placeholder="Select Property Type"
                  options={[
                    ...item?.childs?.map((itm) => {
                      return {
                        value: itm?.value,
                        label: itm?.label,
                      };
                    }),
                  ]}
                ></Select>
              </Form.Item>
            );
          }
          return null;
        })}

        {static_fields?.map((item) => {
          if (item?.attr?.label === "Available from") {
            return (
              <Form.Item
                name={item?.attr?.name}
                label={item?.attr?.label}
                rules={[
                  {
                    required: item?.is_required,
                    message: "Avaialbe From is required",
                  },
                ]}
                className="two--row--child--container"
              >
                <DatePicker
                  size="large"
                  disabledDate={(cd) => {
                    return cd < moment().subtract(1, "days");
                  }}
                  placeholder="DD-MM-YYYY"
                  format="DD--MM--YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            );
          }
        })}
      </div>
      <div className="two--row--container">
        {static_fields?.map((item) => {
          if (item?.attr?.name === "house_area_type") {
            return (
              <div className="two--row--child--container">
                <Form.Item
                  name={item?.attr?.name}
                  label={item?.attr?.label}
                  style={{ marginBottom: "0.5rem" }}
                >
                  <Select
                    onChange={(value) => setHouseAreaType(value)}
                    size="large"
                    placeholder="Select House Area Type"
                    options={[
                      ...item?.childs?.map((itm) => {
                        return {
                          label: itm?.label,
                          value: itm?.value,
                        };
                      }),
                    ]}
                  />
                </Form.Item>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "0.5rem",
                    width: "100%",
                    marginBottom: "1rem",
                  }}
                >
                  {item?.childs
                    ?.filter(
                      (houseType) => houseType?.value === houseAreaType
                    )[0]
                    ?.values?.map((itm) => (
                      <Form.Item
                        style={{ margin: 0 }}
                        label={itm?.label}
                        name={["houseAreaType", itm?.name]}
                      >
                        <InputNumber
                          size="large"
                          style={{ width: "100%" }}
                          defaultValue={0}
                        />
                      </Form.Item>
                    ))}
                </div>
              </div>
            );
          }
        })}

        {static_fields?.map((item) => {
          if (item?.attr?.name === "land_area_type") {
            return (
              <div className="two--row--child--container">
                <Form.Item
                  name={item?.attr?.name}
                  label={item?.attr?.label}
                  style={{ marginBottom: "0.5rem" }}
                >
                  <Select
                    onChange={(value) => setLandAreaType(value)}
                    size="large"
                    placeholder="Select Land Area Type"
                    options={[
                      ...item?.childs?.map((itm) => {
                        return {
                          label: itm?.label,
                          value: itm?.value,
                        };
                      }),
                    ]}
                  />
                </Form.Item>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "0.5rem",
                    width: "100%",
                    marginBottom: "1rem",
                  }}
                >
                  {item?.childs
                    ?.filter((landType) => landType?.value === landAreaType)[0]
                    ?.values?.map((itm) => (
                      <Form.Item
                        style={{ margin: 0 }}
                        label={itm?.label}
                        name={["landAreaType", itm?.name]}
                      >
                        <InputNumber
                          size="large"
                          style={{ width: "100%" }}
                          defaultValue={0}
                        />
                      </Form.Item>
                    ))}
                </div>
              </div>
            );
          }
        })}
      </div>

      <div className="two--row--container">
        {static_fields?.map((item) => {
          if (item?.attr?.name === "area") {
            return (
              <Form.Item
                className="two--row--child--container"
                name={item?.attr?.name}
                label={item?.attr?.label}
              >
                <InputNumber
                  type="number"
                  placeholder="M * M"
                  style={{ width: "100%" }}
                  min={0}
                />
              </Form.Item>
            );
          } else if (item?.attr?.name === "house_area") {
            return (
              <Form.Item
                className="two--row--child--container"
                name={item?.attr?.name}
                label={item?.attr?.label}
              >
                <InputNumber
                  type="number"
                  placeholder="M * M"
                  style={{ width: "100%" }}
                  min={0}
                />
              </Form.Item>
            );
          }
          return null;
        })}
      </div>
      <div className="two--row--container">
        {static_fields?.map((item) => {
          if (item?.attr?.name === "no_room") {
            return (
              <Form.Item
                className="two--row--child--container"
                label={item?.attr?.label}
                name={item?.attr?.name}
              >
                <Select
                  size="large"
                  placeholder="Select Room Number"
                  options={[
                    ...item?.childs?.map((itm) => {
                      return {
                        value: itm?.value,
                        label: itm?.label,
                      };
                    }),
                  ]}
                ></Select>
              </Form.Item>
            );
          } else if (item?.attr?.name === "Number_of_Bathrooms") {
            return (
              <Form.Item
                className="two--row--child--container"
                label={item?.attr?.label}
                name={item?.attr?.name}
              >
                <Select
                  size="large"
                  placeholder="Select Bathroom Number"
                  options={[
                    ...item?.childs?.map((itm) => {
                      return {
                        value: itm?.value,
                        label: itm?.label,
                      };
                    }),
                  ]}
                ></Select>
              </Form.Item>
            );
          }
          return null;
        })}
      </div>
      <div className="two--row--container">
        {static_fields?.map((item) => {
          if (item?.attr?.name === "no_of_car_space") {
            return (
              <Form.Item
                className="two--row--child--container"
                label={item?.attr?.label}
                name={item?.attr?.name}
              >
                <Select
                  size="large"
                  placeholder="Select car space"
                  options={[
                    ...item?.childs?.map((itm) => {
                      return {
                        value: itm?.value,
                        label: itm?.label,
                      };
                    }),
                  ]}
                ></Select>
              </Form.Item>
            );
          } else if (item?.attr?.name === "Parking") {
            return (
              <Form.Item
                className="two--row--child--container"
                label={item?.attr?.label}
                name={item?.attr?.name}
              >
                <Select
                  size="large"
                  placeholder="Select parking type"
                  options={[
                    ...item?.childs?.map((itm) => {
                      return {
                        value: itm?.value,
                        label: itm?.label,
                      };
                    }),
                  ]}
                ></Select>
              </Form.Item>
            );
          }
          return null;
        })}
      </div>
      <div className="two--row--container">
        <Form.Item
          className="two--row--child--container"
          name="title"
          label="Ad Title"
          rules={[
            {
              required: true,
              message: "Ad Title is required",
            },
          ]}
        >
          <Input size="large" placeholder="Enter Ad Title" />
        </Form.Item>

        <Form.Item
          className="two--row--child--container"
          name="description"
          label="Ad Description"
          rules={[
            {
              required: true,
              message: "Ad Description is required",
            },
          ]}
        >
          <TextArea size="large" placeholder="e.g abc" rows={4} />
        </Form.Item>
      </div>

      {/* <div className="two--row--container">
        {static_fields?.map((item) => {
          if (item?.attr?.name === "post_indoor_features") {
            return (
              <Form.Item
                className="two--row--child--container"
                name={item?.attr?.name}
                label={item?.attr?.label}
              >
                <CheckboxGroup
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridGap: "1rem",
                    padding: "1rem",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: "0.5rem",
                    maxHeight: "16rem",
                    overflowY: "scroll",
                    border: "1px solid #DBDFEA",
                  }}
                  options={[
                    ...item?.childs?.map((itm) => {
                      return {
                        label: (
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "0.5rem",
                              alignItems: "center",
                            }}
                          >
                            <img
                              style={{
                                width: "2rem",
                                height: "2rem",
                                objectFit: "contain",
                              }}
                              src={itm?.image_name}
                              alt=""
                            />{" "}
                            {itm?.label}
                          </span>
                        ),
                        value: itm?.value,
                      };
                    }),
                  ]}
                />
              </Form.Item>
            );
          } else if (item?.attr?.name === "post_outdoor_features") {
            return (
              <Form.Item
                className="two--row--child--container"
                name={item?.attr?.name}
                label={item?.attr?.label}
              >
                <CheckboxGroup
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "1rem",
                    padding: "1rem",
                    alignItems: "center",
                    backgroundColor: "white",
                    borderRadius: "0.5rem",
                    height: "100%",
                    maxHeight: "16rem",
                    overflowY: "scroll",
                    border: "1px solid #DBDFEA",
                  }}
                  options={[
                    ...item?.childs?.map((itm) => {
                      return {
                        label: (
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "0.5rem",
                              alignItems: "center",
                            }}
                          >
                            <img
                              style={{
                                width: "2rem",
                                height: "2rem",
                                objectFit: "contain",
                              }}
                              src={itm?.image_name}
                              alt=""
                            />{" "}
                            {itm?.label}
                          </span>
                        ),
                        value: itm?.value,
                      };
                    }),
                  ]}
                  className="two-column-checkbox"
                />
              </Form.Item>
            );
          }
          return null;
        })}
      </div> */}

      <div className="two--row--container">
        <Form.Item
          className="two--row--child--container"
          label="Inspection Time"
        >
          <Form.List name="inspection_date_times">
            {(fields, { add, remove }) => (
              <div
                style={{
                  maxHeight: "16rem",
                  overflow: "scroll",
                  backgroundColor: "white",
                  borderRadius: "0.5rem",
                  overflowY: "scroll",
                  border: "1px solid #DBDFEA",
                  padding: "1rem 0 0 1rem",
                }}
              >
                <Button style={{ marginBottom: "1rem" }} onClick={() => add()}>
                  <PlusOutlined /> Inspection
                </Button>
                {fields.map((field, index) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                    key={field.key}
                  >
                    <Space>
                      <Form.Item
                        label="Date"
                        name={[field.name, "date"]}
                        fieldKey={[field.fieldKey, "date"]}
                      >
                        <DatePicker
                          disabledDate={(cd) => {
                            return cd < moment().subtract(1, "days");
                          }}
                          format="DD--MM--YYYY"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Start Time"
                        name={[field.name, "start_time"]}
                        fieldKey={[field.fieldKey, "start_time"]}
                      >
                        <TimePicker placeholder="Start Time" format="h:mm A" />
                      </Form.Item>
                      <Form.Item
                        label="End Time"
                        name={[field.name, "end_time"]}
                        fieldKey={[field.fieldKey, "end_time"]}
                      >
                        <TimePicker placeholder="End Time" format="h:mm A" />
                      </Form.Item>
                    </Space>
                    <DeleteOutlined
                      onClick={() => remove(field.name)}
                      style={{
                        fontSize: "1.5rem",
                        color: "#FF8400",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
          </Form.List>
        </Form.Item>
      </div>
      <div className="two--row--container">
        {static_fields?.map((item) => {
          if (item?.attr?.name === "house_type") {
            return (
              <Form.Item
                className="two--row--child--container"
                name={item?.attr?.name}
                label={item?.attr?.label}
                rules={[
                  {
                    required: item?.is_required,
                    message: "Select House Type",
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select House Type status"
                  options={[
                    ...item?.childs?.map((itm) => {
                      return { label: itm?.label, value: itm?.value };
                    }),
                  ]}
                ></Select>
              </Form.Item>
            );
          }
          return null;
        })}
        {static_fields?.map((item) => {
          if (item?.attr?.label === "Furnished") {
            return (
              <Form.Item
                className="two--row--child--container"
                name={item?.attr?.name}
                label={item?.attr?.label}
                rules={[
                  {
                    required: item?.is_required,
                    message: "Select Furnished Type",
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="Select Furnished status"
                  options={[
                    ...item?.childs?.map((itm) => {
                      return { label: itm?.label, value: itm?.value };
                    }),
                  ]}
                ></Select>
              </Form.Item>
            );
          }
          return null;
        })}
      </div>
    </>
  );
};

export default PostAnForm1;
