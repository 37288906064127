import React, { useEffect, useState, useLayoutEffect } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, connect } from "react-redux";
import { Card, Modal } from "antd";
import { SettingOutlined, UserSwitchOutlined } from "@ant-design/icons";

import DashboardLayout from "../../layout/Layout";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import {
  FeatureProperty,
  ActivityAdView,
  TodoList,
  QuickInquiry,
} from "../../component";
import { allActions } from "../../Redux/myActions";
import { PieChart } from "../../component/charts/PieChart";
import OffersMessage from "../../component/offerMessage/OffersMessage";
import LatestProperty from "../../component/insight/LatestProperty";
import PieChartSkeleton from "../../skeleton/insight/PieChartSkeleton";
import { GiTwoCoins } from "react-icons/gi";

const mapStateToProps = (store) => {
  const { PropertyReducer, ProfileReducer } = store;
  return { PropertyReducer, ProfileReducer };
};

const Insight = ({ PropertyReducer, ProfileReducer }) => {
  const dispatch = useDispatch();
  const { changetodoloading, profileData } = ProfileReducer;
  const { loading, realEstateData, allPropertyData } = PropertyReducer;
  console.log("data data", allPropertyData);
  useEffect(() => {
    window.scrollTo(0, { behavior: "smooth" });
  }, []);

  useLayoutEffect(() => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "profile",
          attempt: "PROFILE_REQUEST_ATTEMPT",
          success: "PROFILE_REQUEST_SUCCESS",
          failure: "PROFILE_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );

    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: `my_ads?api_from=classiEstate&order=Most Recent`,
          attempt: "FETCH_ALL_PROPERTY_REQUEST_ATTEMPT",
          success: "FETCH_ALL_PROPERTY_REQUEST_SUCCESS",
          failure: "FETCH_ALL_PROPERTY_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  }, []);

  const [soldRented, setSoldRented] = useState([
    {
      type: "Sold Out",
      value: allPropertyData?.sold_property,
    },
    {
      type: "Rented",
      value: allPropertyData?.rent_property,
    },
    {
      type: "Remain",
      value:
        Number(allPropertyData?.all_ads) -
        Number(allPropertyData?.sold_property) -
        Number(allPropertyData?.rent_property),
    },
  ]);
  const [offerInquiry, setOfferInquiry] = useState([
    {
      type: "Inquiry",
      value: allPropertyData?.offers,
    },
  ]);
  const [sellRent, setSellRent] = useState([
    {
      type: "On Sell",
      value: allPropertyData?.property_on_rent,
    },
    {
      type: "On Rent",
      value: allPropertyData?.property_on_sale,
    },
  ]);
  const [AdStatus, setAdStatus] = useState([
    {
      type: "Complete",
      value: allPropertyData?.completed_ads,
    },
    {
      type: "Active",
      value: allPropertyData?.active_ads,
    },
    {
      type: "Inactive",
      value: allPropertyData?.inactive_ads,
    },
  ]);

  useEffect(() => {
    if (PropertyReducer?.realEstateData) {
      const product_details =
        PropertyReducer?.realEstateData?.realestate_detail;

      setOfferInquiry([
        {
          type: "Inquiry",
          value: product_details?.offers,
        },
      ]);

      setAdStatus([
        {
          type: "Featured",
          value: product_details?.total_featured_properties,
        },
        {
          type: "Spotlight",
          value: product_details?.total_spotlight,
        },
        {
          type: "Urgent",
          value: product_details?.total_urgent,
        },
      ]);

      setSellRent([
        {
          type: "On Sell",
          value: product_details?.total_properties_on_sale,
        },
        {
          type: "On Rent",
          value: product_details?.total_properties_on_rent,
        },
      ]);

      setSoldRented([
        {
          type: "Sold Out",
          value: product_details?.sold_properties,
        },
        {
          type: "Rented",
          value: product_details?.rent_properties,
        },
        {
          type: "Remain",
          value:
            Number(product_details?.total_properties) -
            Number(product_details?.sold_properties) -
            Number(product_details?.rent_properties),
        },
      ]);
    }
  }, [PropertyReducer?.realEstateData]);

  const crumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
  ];

  return (
    <DashboardLayout>
      <div className="dashboard--container">
        <Breadcrumb title="DashBoard" crumbList={crumbList} />
        <div className="notification--container">
          <h3
            style={{
              flex: 1,
              fontWeight: 500,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            You have{" "}
            <span
              style={{
                color: "orange",
                display: "flex",
                flexDirection: "row",
                gap: "0.2rem",
                alignItems: "center",
                margin: "0 0.5rem",
              }}
            >
              <GiTwoCoins style={{ fontSize: "2rem" }} />{" "}
              <span style={{ color: "#0b3e75ce", fontWeight: 600 }}>
                {profileData?.classi_credit}
              </span>
            </span>{" "}
            Coins available in your Wallet.
          </h3>
          <div className="notification--container--body">
            <NavLink
              to="https://classibazaar.com.au/notification_setting_list"
              className="button--modal--primary"
              style={{ gap: "0.5rem" }}
            >
              <SettingOutlined /> {`Notification Settings`}
            </NavLink>
            <NavLink
              to="/referral/invite-friend"
              className="button--modal--primary"
              style={{ gap: "0.5rem" }}
            >
              <UserSwitchOutlined /> {`  Invite Friends`}
            </NavLink>
          </div>
        </div>
        {loading ? (
          <PieChartSkeleton />
        ) : (
          <div className="piechart--property--container">
            {AdStatus && <PieChart data={AdStatus} />}
            {soldRented && <PieChart data={soldRented} />}
            {sellRent && <PieChart data={sellRent} />}
            {offerInquiry && <PieChart data={offerInquiry} />}
          </div>
        )}
        <QuickInquiry />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <ActivityAdView />
        </div>
        <div className="todo--offermessage--container">
          <OffersMessage />
          <TodoList />
        </div>
        <LatestProperty />
      </div>
    </DashboardLayout>
  );
};

export default connect(mapStateToProps)(Insight);
