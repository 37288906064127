import React, { useState, useRef, useEffect } from "react";
import {
  Form,
  Checkbox,
  Button,
  Alert,
  Input,
  message,
  Space,
  Select,
  Table,
} from "antd";
import {
  FileImageOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import Marquee from "react-fast-marquee";
import AddAgentDrawer from "../Drawer/AddAgentDrawer";
import CurrentScreenSize from "../screenSizeReader/CurrentScreenSize.js";
import { allActions } from "../../Redux/myActions";
import { useDispatch, connect } from "react-redux";
import MainLoader from "../loader/MainLoader";

const mapStateToProps = (store) => {
  const { AgentAgencyReducer, PropertyReducer } = store;
  return { AgentAgencyReducer, PropertyReducer };
};

const PostAdForm2 = ({
  form,
  AgentAgencyReducer,
  PropertyReducer,
  postImages,
  setImag,
  floor_plans,
  setImagList,
  mediaFile,
  setMediaFile,
}) => {
  const dispatch = useDispatch();
  const imageRef = useRef();
  const imageListRef = useRef();
  const CheckboxGroup = Checkbox.Group;
  const isLarge = CurrentScreenSize();
  const handleAddAgent = () => {
    setAgentDrawer(true);
  };
  const { agentData } = AgentAgencyReducer;
  const { loading, roomloading, dimageloading, mediaType } = PropertyReducer;

  const [agentDrawer, setAgentDrawer] = useState(false);
  const [isChoosenMedia, setIsChoosenMedia] = useState(false);

  const columns = [
    {
      title: "Media Type",
      align: "center",
      dataIndex: "media_type",
    },
    {
      title: "Media Link",
      align: "center",
      width: "70%",

      dataIndex: "link_url",
    },
    {
      title: "Action",
      align: "center",
      render: (_, data, index) => (
        <CloseCircleOutlined
          onClick={() => handleRemoveMediaFile(index)}
          style={{ fontSize: "1.5rem", cursor: "pointer", color: "red" }}
        />
      ),
    },
  ];

  useEffect(() => {
    fetchAgentList();
  }, []);

  const fetchAgentList = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "agent/list_agent",
          attempt: "FETCH_AGENT_REQUEST_ATTEMPT",
          success: "FETCH_AGENT_REQUEST_SUCCESS",
          failure: "FETCH_AGENT_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  };

  const handleRemoveMediaFile = (index) => {
    setMediaFile((prev) => prev.filter((_, ind) => index !== ind));
  };

  const handleMediatypeChange = (value) => {
    value && setIsChoosenMedia(true);
  };

  const handleAddMediaType = () => {
    var pattern = /^\s*$/;
    const temp = form.getFieldValue("txt_media_type");
    if (!temp?.link_url || pattern.test(temp?.link_url)) {
      message.warning("Please Enter the Media Url");
    } else {
      setMediaFile((prev) => [...prev, temp]);
      form.resetFields(["txt_media_type"]);
      setIsChoosenMedia((prev) => !prev);
    }
  };

  const handlePropertyImageUpload = (event) => {
    const fileList = Array?.from(event.target.files);
    const form = new FormData();
    fileList?.map((file, index) => form.append(`files[]`, file));

    dispatch(
      allActions(form, {
        method: "post",
        endPoint: "upload_post_image",
        attempt: "UPLOAD_PROPERTY_IMAGE_REQUEST_ATTEMPT",
        success: "UPLOAD_PROPERTY_IMAGE_REQUEST_SUCCESS",
        failure: "UPLOAD_PROPERTY_IMAGE_REQUEST_FAILURE",

        saveBearerToken: false,
        multipartFormData: true,
        successInternalState: (res) => {
          setImag((prev) => [...prev, ...res?.source]);
        },
        failureInternalState: () => {},
      })
    );
  };

  const handleRemovePropertyImage = (img, index) => {
    dispatch(
      allActions(
        { image_name: img },
        {
          method: "post",
          endPoint: "remove_image",
          attempt: "PF_DELETE_IMAGE_REQUEST_ATTEMPT",
          success: "PF_DELETE_IMAGE_REQUEST_SUCCESS",
          failure: "PF_DELETE_IMAGE_REQUEST_FAILURE",

          saveBearerToken: false,
          multipartFormData: false,
          successInternalState: () => {
            message.success("Image Deleted Successfully");
            setImag(postImages.filter((img, indx) => index !== indx));
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  const handleFileUpload = (event) => {
    const fileList = Array?.from(event.target.files);
    const form = new FormData();
    fileList?.map((file, index) => form.append(`files[]`, file));
    dispatch(
      allActions(form, {
        method: "post",
        endPoint: "upload_post_image",
        attempt: "UPLOAD_ROOM_IMAGE_REQUEST_ATTEMPT",
        success: "UPLOAD__ROOM_IMAGE_REQUEST_SUCCESS",
        failure: "UPLOAD__ROOM_IMAGE_REQUEST_FAILURE",

        saveBearerToken: false,
        multipartFormData: true,
        successInternalState: (res) => {
          setImagList((prev) => [...prev, ...res?.source]);
        },
        failureInternalState: () => {},
      })
    );
  };

  const handleRemoveImage = (img, index) => {
    dispatch(
      allActions(
        { image_name: img },
        {
          method: "post",
          endPoint: "remove_image",
          attempt: "PF_DELETE_IMAGE_REQUEST_ATTEMPT",
          success: "PF_DELETE_IMAGE_REQUEST_SUCCESS",
          failure: "PF_DELETE_IMAGE_REQUEST_FAILURE",

          saveBearerToken: false,
          multipartFormData: false,
          successInternalState: () => {
            message.success("Image Deleted Successfully");
            setImagList(floor_plans.filter((img, indx) => index !== indx));
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  return (
    <>
      {dimageloading && <MainLoader />}
      <AddAgentDrawer
        handleFetchAgent={fetchAgentList}
        agentDrawer={agentDrawer}
        setAgentDrawer={setAgentDrawer}
      />
      <Form.Item
        style={{
          marginTop: "1rem",
        }}
        label="Agent"
      >
        <Button style={{ marginBottom: "1rem" }} onClick={handleAddAgent}>
          Add Agent
        </Button>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Agent is required",
            },
          ]}
          name="agents"
        >
          <CheckboxGroup
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              gridGap: "1rem",
              padding: "1rem",
              backgroundColor: "white",
              borderRadius: "0.5rem",
              maxHeight: "10rem",
              overflowY: "scroll",
              alignItems: "center",
              border: "1px solid #DBDFEA",
            }}
            options={[
              ...agentData?.map((data) => {
                return {
                  label: `${data?.first_name} ${data?.last_name}`,
                  value: data?.id,
                };
              }),
            ]}
            className="two-column-checkbox"
          />
        </Form.Item>
      </Form.Item>

      <div className="two--row--container">
        <Form.Item
          className="two--row--child--container"
          label="Select Property Images"
        >
          {postImages?.length === 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                width: "fit-content",
              }}
            >
              <FileImageOutlined
                style={{
                  fontSize: "8rem",
                  color: "gray",
                  cursor: "pointer",
                  padding: "1rem",
                  borderRadius: "0.5rem",
                  backgroundColor: "white",
                }}
                className="post--ad--image"
              />
              <Button
                onClick={() => imageRef?.current?.click()}
                style={{
                  backgroundColor: "transparent",
                  borderRadius: "3rem",
                  fontWeight: "800",
                  border: "3px solid #F99417",
                  color: "#F99417",
                }}
                className="post--ad--image--upload"
              >
                UpLoad
              </Button>
            </div>
          ) : (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "0.5rem",
                border: "1px solid #DBDFEA",
                background: "white",
                padding: "0.5rem",
                borderRadius: "0.5rem",
                maxHeight: "20rem",
                overflowY: "scroll",
              }}
            >
              {postImages?.map((img, index) => (
                <div key={img} className="image--container">
                  <img
                    className="floor--image"
                    style={{
                      maxHeight: "8rem",
                      height: "auto",
                      width: "100%",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                    src={img?.image_name}
                    alt="img"
                  />
                  <DeleteOutlined
                    onClick={() => handleRemovePropertyImage(img, index)}
                    className="delete--floor--image"
                  />
                </div>
              ))}
            </div>
          )}
          <Form.Item
            style={{ marginTop: "-2rem" }}
            name="imageProperty"
            rules={[
              {
                required: postImages?.length > 0 ? false : true,
                message: "Property image is required",
              },
            ]}
          >
            <input
              type="file"
              accept=".png, .jpg, .jpeg, .gif"
              multiple
              style={{ display: "none" }}
              ref={imageRef}
              onChange={handlePropertyImageUpload}
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "1rem 0",
              gap: "0.5rem",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <Alert
              className="alert--message"
              banner
              message={
                <Marquee pauseOnHover gradient={false}>
                  Image must be in JPG, PNG, or GIF formats.
                </Marquee>
              }
            />
            {postImages?.length > 0 && (
              <div
                style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
              >
                <Button onClick={() => imageRef.current.click()}>Upload</Button>
              </div>
            )}{" "}
          </div>
        </Form.Item>
        <Form.Item
          className="two--row--child--container"
          label="Add Floor Plan Image"
        >
          {floor_plans.length === 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                width: "fit-content",
              }}
            >
              <FileImageOutlined
                style={{
                  fontSize: "8rem",
                  color: "gray",
                  cursor: "pointer",
                  padding: "1rem",
                  borderRadius: "0.5rem",
                  backgroundColor: "white",
                }}
                className="post--ad--image"
              />
              <Button
                onClick={() => imageListRef.current.click()}
                style={{
                  backgroundColor: "transparent",
                  borderRadius: "3rem",
                  fontWeight: "800",
                  border: "3px solid #F99417",
                  color: "#F99417",
                }}
                className="post--ad--image--upload"
              >
                UpLoad
              </Button>
            </div>
          ) : (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "0.5rem",
                background: "white",
                padding: "0.5rem",
                borderRadius: "0.5rem",
                maxHeight: "20rem",
                overflowY: "scroll",
                border: "1px solid #DBDFEA",
              }}
            >
              {floor_plans.map((img, index) => (
                <div key={img} className="image--container">
                  <img
                    className="floor--image"
                    style={{
                      maxHeight: "8rem",
                      width: "100%",
                      height: "auto",
                      cursor: "pointer",
                      objectFit: "cover",
                    }}
                    src={img?.image_name}
                    alt="img"
                  />
                  <DeleteOutlined
                    onClick={() => handleRemoveImage(img, index)}
                    className="delete--floor--image"
                  />
                </div>
              ))}
            </div>
          )}

          <input
            type="file"
            accept=".png, .jpg, .jpeg, .gif"
            multiple
            style={{ display: "none" }}
            ref={imageListRef}
            onChange={handleFileUpload}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "1rem 0",
              gap: "0.5rem",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <Alert
              className="alert--message"
              banner
              message={
                <Marquee pauseOnHover gradient={false}>
                  Image must be in JPG, PNG, or GIF formats.
                </Marquee>
              }
            />
            {floor_plans.length > 0 && (
              <div
                style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
              >
                <Button onClick={() => imageListRef.current.click()}>
                  Upload
                </Button>
              </div>
            )}{" "}
          </div>
        </Form.Item>
      </div>
      <Form.Item label="Add Media">
        <Space.Compact
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          size="large"
        >
          <Form.Item
            name={["txt_media_type", "media_type"]}
            style={{ width: "25%", margin: 0 }}
          >
            <Select
              size="large"
              onChange={handleMediatypeChange}
              placeholder="Select Media Type"
              options={Object.keys(mediaType)?.map((item) => {
                return {
                  label: item,
                  value: item,
                };
              })}
            ></Select>
          </Form.Item>
          <Form.Item
            style={{ width: "100%", margin: 0 }}
            name={["txt_media_type", "link_url"]}
            rules={[
              isChoosenMedia === true && {
                required: true,
                message: "Please Select the Media Type",
              },
              isChoosenMedia === true && {
                type: "url",
                message: "Please enter a valid URL",
              },
            ]}
          >
            <Input
              size="large"
              readOnly={!isChoosenMedia}
              style={{ minWidth: "50%" }}
              placeholder="Enter Link Url"
            />
          </Form.Item>
          <PlusCircleOutlined
            style={{ fontSize: "2rem", marginLeft: "0.5rem", color: "#3a6390" }}
            onClick={handleAddMediaType}
          />
        </Space.Compact>
        {mediaFile?.length > 0 && (
          <Table
            style={{ margin: "0.5rem 0" }}
            scroll={{ y: 200 }}
            dataSource={mediaFile}
            columns={columns}
            pagination={false}
          />
        )}
      </Form.Item>
    </>
  );
};

export default connect(mapStateToProps)(PostAdForm2);
