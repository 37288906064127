import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { allActions } from "../Redux/myActions";

export default function useNotificationHook(setNotification, pageNumber) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: `user_notifications?api_from=classiEstate&page=${pageNumber}`,
          attempt: "FETCH_NOTIFICATION_REQUEST_ATTEMPT",
          success: "FETCH_NOTIFICATION_REQUEST_SUCCESS",
          failure: "FETCH_NOTIFICATION_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: (res) => {
            setNotification((prev) => [...prev, ...res?.notifications]);
          },
          failureInternalState: () => {},
        }
      )
    );
  }, [pageNumber]);

  return null;
}
