import React, { useEffect } from "react";
import { Button, DatePicker, Divider, Select, Table } from "antd";
import { useDispatch, connect } from "react-redux";
import {
  ExclamationCircleOutlined,
  LeftOutlined,
  RightOutlined,
  SyncOutlined,
} from "@ant-design/icons";

import DashboardLayout from "../../layout/Layout";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import Breadcrumb1 from "../../component/breedcrumb/Breadcrumb1";
import { allActions } from "../../Redux/myActions";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { IsScrollHeight } from "../../Helpers/FrontendHelper";

const crumbList = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Inqury List",
    link: "/find-agent-inquiry/inquiry-list",
  },
];

const mapStateToProps = (store) => {
  const AgentAgencyReducer = store?.AgentAgencyReducer;
  return { AgentAgencyReducer };
};
const InquryList = ({ AgentAgencyReducer }) => {
  const { loading, inquiryListData, inquirystatusloading } = AgentAgencyReducer;
  const isSticky = IsScrollHeight();
  const dispatch = useDispatch();
  useEffect(() => {
    fetchAgentEnquiryList(1);
  }, []);

  const fetchAgentEnquiryList = (pageNo, status = "") => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: `find_agent_enquiry?api_from=classiEstate&status=${status}&page=${pageNo}`,
          attempt: "FETCH_AGENT_ENQUIRYLIST_REQUEST_ATTEMPT",
          success: "FETCH_AGENT_ENQUIRYLIST_REQUEST_SUCCESS",
          failure: "FETCH_AGENT_ENQUIRYLIST_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  };

  if (loading) {
    return (
      <DashboardLayout>
        <Breadcrumb title="Inqury List" crumbList={crumbList} />
        <h3>Loading...</h3>
      </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <Breadcrumb title="Inqury List" crumbList={crumbList} />

      <div className="inquiry--credit--list--container">
        {inquiryListData && (
          <>
            {" "}
            <InquirySearchListComponent inquiryListData={inquiryListData} />
            <div className="inquiry--creditlist--container--body">
              <MyEnquiryListComponent
                loading={inquirystatusloading}
                fetchAgentEnquiryList={fetchAgentEnquiryList}
                inquiryListData={inquiryListData}
              />{" "}
              <div
                style={{
                  marginTop: "1rem",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "0.5rem",
                  alignItems: "center",
                }}
              >
                <Button
                  disabled={Number(inquiryListData?.current_page) === 1}
                  onClick={() =>
                    fetchAgentEnquiryList(
                      Number(inquiryListData?.current_page) - 1
                    )
                  }
                >
                  <LeftOutlined />
                </Button>
                <Button className="button--modal--primary" type="primary">
                  {inquiryListData?.current_page}
                </Button>
                <Button
                  onClick={() =>
                    fetchAgentEnquiryList(
                      Number(inquiryListData?.current_page) + 1
                    )
                  }
                  disabled={
                    !inquiryListData?.has_next_page ||
                    inquiryListData?.enquiries.length === 0
                  }
                >
                  <RightOutlined />
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </DashboardLayout>
  );
};

export default connect(mapStateToProps)(InquryList);

export const InquirySearchListComponent = ({ inquiryListData }) => {
  const onChange = (date, dateString) => {};
  return (
    <div className="inquiry--creditlist--container--header">
      <h3
        style={{
          display: "flex",
          gap: "0.2rem",
          alignItems: "center",
        }}
      >
        Enquiry Credits Remaining:
        <span style={{ color: "#396493" }}>
          {inquiryListData?.user_credits}
        </span>
      </h3>
      <Divider style={{ margin: "0.8rem 0" }} />
      <div className="inquiry--header--inner--container">
        <span className="agentinqury--field--wrapper">
          <DatePicker
            className="date--picker--agentinqury"
            size="large"
            placeholder="From Date"
            onChange={onChange}
          />
        </span>
        <span className="agentinqury--field--wrapper">
          <DatePicker
            className="date--picker--agentinqury"
            size="large"
            placeholder="To Date"
            onChange={onChange}
          />
        </span>
        <span className="agentinqury--field--wrapper">
          <Select
            bordered={false}
            className="date--picker--agentinqury"
            size="large"
            placeholder="Payment Method"
            options={[
              {
                label: "All",
                value: "all",
              },
              {
                label: "Stripe",
                value: "stripe",
              },
              {
                label: "Paypal",
                value: "paypal",
              },
              {
                label: "Classi Credit",
                value: "classi-credit",
              },
            ]}
          />
        </span>
        <Button shape="round" className="button--modal--primary" size="large">
          Search
        </Button>
      </div>
    </div>
  );
};

export const MyEnquiryListComponent = ({
  inquiryListData,
  fetchAgentEnquiryList,
  loading,
}) => {
  const dispatch = useDispatch();
  const { status } = inquiryListData;
  const navigate = useNavigate();
  const handleNaviagteToEnquiryDetail = (data) => {
    localStorage.setItem("inquiryData", JSON.stringify(data));
    navigate(`/enquiry-detail/${data?.id}`);
  };
  const [inquiryType, setInquiryType] = useState("");
  const [statusInquiry, setStatus] = useState("");

  const handleUpdateInquiryStatus = (data, { status }) => {
    dispatch(
      allActions(
        {
          is_approved: status,
          enquiry_id: data?.id,
        },
        {
          method: "post",
          endPoint: `action_enquiry`,
          attempt: "UPDATE_INQUIRY_STATUS_REQUEST_ATTEMPT",
          success: "UPDATE_INQUIRY_STATUS_REQUEST_SUCCESS",
          failure: "UPDATE_INQUIRY_STATUS_REQUEST_FAILURE",
          saveBearerToken: false,
          successInternalState: () => {
            setTimeout(() => {
              fetchAgentEnquiryList(1);
            }, 1000);
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  const columns = [
    {
      title: "SN",
      align: "center",
      render: (text, record, index) => {
        return <span> {Number(index) + 1}</span>;
      },
    },
    {
      title: "Contact Detail",
      align: "center",
      render: (data) => {
        return (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
          >
            {data?.status?.toLowerCase() === "pending" ||
            data?.status?.toLowerCase() === "declined" ? (
              "xxxx xxxx"
            ) : (
              <>
                <span>{data?.name}</span>
                <span>{data?.email}</span>
              </>
            )}
          </div>
        );
      },
    },
    {
      title: "Enquiry For",
      align: "center",
      dataIndex: "enquiry_for",
    },
    {
      title: "Type",
      align: "center",
      dataIndex: "nature_of_property",
    },
    {
      title: "Property Condition",
      align: "center",
      dataIndex: "property_condition",
    },
    {
      title: "Locations",
      align: "center",
      dataIndex: "location",
      render: (location) => {
        return (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
          >
            {" "}
            {location?.map((item) => (
              <span>{item}</span>
            ))}
          </div>
        );
      },
    },
    {
      title: "Status",
      align: "center",
      dataIndex: "status",
      render: (status) => {
        if (status === "Approved") {
          return (
            <Button
              size="small"
              style={{
                background: "#03C988",
                borderColor: "#03C988",
                color: "white",
              }}
            >
              {status}
            </Button>
          );
        } else if (status === "Declined") {
          return (
            <Button
              size="small"
              style={{
                background: "#F24C3D",
                borderColor: "#F24C3D",
                color: "white",
              }}
            >
              {status}
            </Button>
          );
        } else if (status === "Pending") {
          return (
            <Button
              size="small"
              style={{
                background: "#62CDFF",
                borderColor: "#62CDFF",
                color: "white",
              }}
              primary
            >
              {status}
            </Button>
          );
        }
      },
    },
    {
      title: "Action",
      align: "center",
      render: (_, data, index) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.4rem",
              justifyContent: "center",
            }}
          >
            <ExclamationCircleOutlined
              onClick={() => handleNaviagteToEnquiryDetail(data)}
              className="inquiry--action--icon"
            />
            {data?.status?.toLowerCase() === "pending" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.4rem",
                }}
              >
                <Button
                  size="small"
                  style={{
                    borderColor: "#03C988",
                    color: "#03C988",
                  }}
                  loading={
                    loading &&
                    inquiryType === data?.id &&
                    statusInquiry === "approve"
                  }
                  onClick={() => {
                    setInquiryType((prev) => data?.id);
                    setStatus("approve");
                    handleUpdateInquiryStatus(data, { status: 1 });
                  }}
                >
                  {loading &&
                  inquiryType === data?.id &&
                  statusInquiry === "approve"
                    ? "Loading..."
                    : "Approve"}
                </Button>
                <Button
                  size="small"
                  style={{
                    borderColor: "#F24C3D",
                    color: "#F24C3D",
                  }}
                  loading={
                    loading &&
                    inquiryType === data?.id &&
                    statusInquiry === "decline"
                  }
                  onClick={() => {
                    setStatus("decline");
                    setInquiryType((prev) => data?.id);
                    handleUpdateInquiryStatus(data, { status: 0 });
                  }}
                >
                  {loading &&
                  inquiryType === data?.id &&
                  statusInquiry === "decline"
                    ? "Loading..."
                    : "Decline"}
                </Button>
              </div>
            )}
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "0.5rem 1rem 1rem",
        }}
      >
        <h3>My Enquiry List</h3>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "0.5rem",
          }}
        >
          <Button
            style={{
              background: "#62CDFF",
              borderColor: "#62CDFF",
              color: "white",
            }}
            primary
            size="small"
            onClick={() => fetchAgentEnquiryList(1, status?.Pending)}
          >
            {status?.Pending}
          </Button>
          <Button
            style={{
              background: "#03C988",
              borderColor: "#03C988",
              color: "white",
            }}
            size="small"
            onClick={() => fetchAgentEnquiryList(1, status?.Approved)}
          >
            {status?.Approved}
          </Button>
          <Button
            size="small"
            style={{
              background: "#F24C3D",
              borderColor: "#F24C3D",
              color: "white",
            }}
            onClick={() => fetchAgentEnquiryList(1, status?.Declined)}
          >
            {status?.Declined}
          </Button>
          <SyncOutlined
            style={{
              fontSize: "1.3rem",
              color: "gray",
              cursor: "pointer",
            }}
            onClick={() => fetchAgentEnquiryList()}
          />
        </div>
      </div>
      <Table
        bordered
        scroll={{ y: 400, x: 800 }}
        pagination={false}
        dataSource={inquiryListData?.enquiries}
        columns={columns}
      />
      ;
    </>
  );
};
