import {
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Checkbox,
  Button,
  Space,
  TimePicker,
  Alert,
  message,
  Table,
  Tooltip,
  Modal,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, connect } from "react-redux";
import { allActions } from "../../Redux/myActions";
import Autocomplete from "react-google-autocomplete";
import ScreenSize from "../screenSizeReader/ScreenSize";
import {
  PlusOutlined,
  DeleteOutlined,
  FileImageOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import CurrentScreenSize from "../screenSizeReader/CurrentScreenSize";
import moment from "moment";
import PostAdForm3 from "../multipartForm/PostAdForm3";
import Marquee from "react-fast-marquee";
import AddAgentDrawer from "../Drawer/AddAgentDrawer";
import MainLoader from "../loader/MainLoader";
import { BsCameraVideo } from "react-icons/bs";

const mapStateToProps = (store) => {
  const { PropertyReducer } = store;
  return { PropertyReducer };
};
const PostEditModal = ({
  editOpenModal,
  setEditOpenModal,
  postId,
  PropertyReducer,
  fetchPropertyList,
}) => {
  const imageRef = useRef();
  const realestatedata = JSON.parse(localStorage.getItem("realestatedata"));
  const imageListRef = useRef();
  const drawerWidth = ScreenSize({ width: "85%" });
  const [propertyLocationList, setPropertyLocationList] = useState([]);
  const CheckboxGroup = Checkbox?.Group;
  const {
    editimageloading,
    postadloading,
    postEditFormData,
    roomloading,
    dimageloading,
    mediaType,
  } = PropertyReducer;
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const isLarge = CurrentScreenSize();
  const dispatch = useDispatch();

  const [static_fields, setStaticFields] = useState([]);
  const [unitSuite, setUnitSuite] = useState("");
  const [isPriceType, setIsPriceType] = useState("");
  const [isPriceChecked, setIsPriceChecked] = useState("false");
  const [postImages, setImag] = useState([]);
  const [floor_plans, setImagList] = useState([]);
  const [agentDrawer, setAgentDrawer] = useState(false);
  const [isChoosenMedia, setIsChoosenMedia] = useState(false);
  const [mediaFile, setMediaFile] = useState([]);
  const [isPreviewVideo, setIsPrevieVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [houseAreaType, setHouseAreaType] = useState("");
  const [landAreaType, setLandAreaType] = useState("");

  const columns = [
    {
      title: "Media Type",
      align: "center",
      dataIndex: "media_type",
    },
    {
      title: "Media Link",
      align: "center",
      width: "70%",

      dataIndex: "link_url",
    },
    {
      title: "Action",
      align: "center",
      render: (_, data, index) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "0.5rem",
          }}
        >
          <Tooltip placement="top" title="Preview Video">
            <BsCameraVideo
              onClick={() => {
                setVideoUrl(data?.link_url);
                setIsPrevieVideo(true);
              }}
              style={{ fontSize: "1.2rem", cursor: "pointer", color: "blue" }}
            />
          </Tooltip>
          <Tooltip placement="top" title="Remove Media link">
            <CloseCircleOutlined
              onClick={() => handleRemoveMediaFile(index)}
              style={{ fontSize: "1.2rem", cursor: "pointer", color: "red" }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const onClose = () => {
    setEditOpenModal(false);
    setHouseAreaType("");
    setLandAreaType("");
    form?.resetFields();
    dispatch({ type: "FETCH_CLEAR_PROPERTY_DETAILS" });
  };

  useEffect(() => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: `ads_edit_form/${postId}?api_from=classiEstate`,
          attempt: "FETCH_POST_EDIT_FORM_REQUEST_ATTEMPT",
          success: "FETCH_POST_EDIT_FORM_REQUEST_SUCCESS",
          failure: "FETCH_POST_EDIT_FORM_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  }, []);

  useEffect(() => {
    postEditFormData &&
      setStaticFields(postEditFormData?.form_fields?.static_fields);
    postEditFormData && setIsPriceType(postEditFormData?.post?.price_type);

    if (postEditFormData?.images?.length > 0) {
      setImag(
        postEditFormData?.images?.filter((img) => img?.image_type === "Image")
      );
      setImagList(
        postEditFormData?.images?.filter(
          (img) => img?.image_type === "Floor_plan"
        )
      );
    }

    postEditFormData && fetchLoactionList(postEditFormData?.post?.state);

    postEditFormData?.post &&
      setMediaFile(
        postEditFormData?.post?.ads_data?.txt_media_type
          ? postEditFormData?.post?.ads_data?.txt_media_type
          : []
      );
    postEditFormData?.post &&
      setHouseAreaType(postEditFormData?.post?.house_size_type);

    postEditFormData?.post &&
      setLandAreaType(postEditFormData?.post?.land_size_type);
  }, [postEditFormData]);

  const fetchLoactionList = (address) => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: `/address_new?address=${address}`,
          attempt: "FETCH_ADDRESS_LIST_REQUEST_ATTEMPT",
          success: "FETCH_ADDRESS_LIST_REQUEST_SUCCESS",
          failure: "FETCH_ADDRESS_LIST_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: (res) => {
            setPropertyLocationList(
              res?.map((item) => {
                return {
                  label: item?.name,
                  value: item?.name,
                };
              })
            );
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  const handleSetAddress = (place) => {
    form.setFieldsValue({ address: place?.formatted_address });
    place?.address_components?.map((item) => {
      if (item.types.includes("street_number")) {
        form.setFieldsValue({
          property_house_number: item?.long_name,
        });
      } else if (item.types.includes("subpremise")) {
        setUnitSuite(item?.long_name);
      } else if (item.types.includes("route")) {
        form.setFieldsValue({
          property_street_name: item?.long_name,
        });
      } else if (item.types.includes("postal_code")) {
        form.setFieldsValue({
          property_postcode: item?.long_name,
        });
      } else if (item.types.includes("locality")) {
        form.setFieldsValue({
          property_suburb: item?.long_name,
        });
      } else if (item.types.includes("administrative_area_level_1")) {
        form.setFieldsValue({
          property_state: item?.short_name,
        });
        fetchLoactionList(item?.short_name);
      }
      return null;
    });
  };

  const handleShowPriceChange = (e) => {
    const checked = e.target.checked;
    const result = e.target.value;
    setIsPriceChecked(result);

    if (!checked) {
      form.setFieldsValue({ price_text: undefined });
    }
  };

  const handleChangePriceType = (e) => {
    setIsPriceType(e?.target?.value);
  };

  const handleUpdateForm = (values) => {
    const inspection_date = values?.inspection_date_times?.map((date_time) => {
      const selectedDate = date_time?.date.format("YYYY-MM-DD");
      return selectedDate;
    });

    const start_hr = values?.inspection_date_times?.map((date_time) => {
      const selectedTime = new Date(date_time?.start_time);
      const hour = selectedTime.getHours();
      return hour;
    });

    const start_min = values?.inspection_date_times?.map((date_time) => {
      const selectedTime = new Date(date_time?.start_time);
      const min = selectedTime.getMinutes();
      return min;
    });

    const start_time = values?.inspection_date_times?.map((date_time) => {
      const selectedTime = new Date(date_time?.start_time);
      const min = selectedTime.getMinutes();
      const hour = selectedTime.getHours();
      return `${hour} : ${min} `;
    });

    const end_hr = values?.inspection_date_times?.map((date_time) => {
      const selectedTime = new Date(date_time?.end_time);
      const hour = selectedTime.getHours();
      return hour;
    });

    const end_min = values?.inspection_date_times?.map((date_time) => {
      const selectedTime = new Date(date_time?.end_time);
      const min = selectedTime.getMinutes();
      return min;
    });

    const end_time = values?.inspection_date_times?.map((date_time) => {
      const selectedTime = new Date(date_time?.end_time);
      const min = selectedTime.getMinutes();
      const hour = selectedTime.getHours();
      return `${hour} : ${min} `;
    });

    dispatch(
      allActions(
        {
          form_id: postEditFormData?.form_fields?.form_id,
          ad_title: values?.title,
          price_type: values?.price_type,
          ad_price: values?.price ? values?.price : 0,
          to_ad_price: values?.to_price ? values?.to_price : 0,
          description: values?.description,
          sell_type: values?.sell_type,
          address: values?.address,
          display_address: values?.address,

          ad_address: values?.address,
          location: values?.address,
          name: `${values?.first_name} ${values?.last_name}`,
          email: values?.email,
          phone: values?.phone,
          show_phone: "1",
          show_price: values?.show_price,
          postcode: values?.property_postcode,
          property_postcode: values?.property_postcode,
          property_house_number: values?.property_house_number,
          property_state: values?.property_state,
          property_street_name: values?.property_street_name,
          property_suburb: values?.property_suburb,
          ad_status: values?.ad_status,
          agents: values?.agents,
          sale_by: "1",
          // display_address: values?.display_address,
          property_type: values?.property_type,
          Property_condition: values?.Property_condition,
          no_room: values?.no_room,
          Number_of_Bathrooms: values?.Number_of_Bathrooms,
          no_of_car_space: values?.no_of_car_space,
          Parking: values?.Parking,
          postImages: [
            ...postImages?.map((img) => {
              return {
                imagename: img?.image_name,
                thumbnail: img?.thumbnail,
              };
            }),
          ],
          floor_plans: [
            ...floor_plans?.map((img) => {
              return {
                imagename: img?.image_name,
                thumbnail: img?.thumbnail,
              };
            }),
          ],
          is_furnished: values?.Is_Furnished,

          inspection_date: inspection_date,
          ticket_start_date: values?.ticket_start_date?.format("YYYY-MM-DD"),
          start_hr: start_hr,
          start_min: start_min,
          start_time: start_time,
          end_hr: end_hr,
          end_min: end_min,
          end_time: end_time,
          post_indoor_features: values?.post_indoor_features,
          post_outdoor_features: values?.post_outdoor_features,
          house_area: values?.house_area,
          area: values?.area,

          price_type2: values?.price_type2 ? values?.price_type2 : "Month",
          security_bound: values?.security_bound,
          txt_media_type: mediaFile,
        },
        {
          method: "post",
          endPoint: `ads_edit_form/${postId}`,
          attempt: "EDIT_AD_FORM_REQUEST_ATTEMPT",
          success: "EDIT_AD_FORM_REQUEST_SUCCESS",
          failure: "EDIT_AD_FORM_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {
            setTimeout(() => {
              setEditOpenModal(false);
              fetchPropertyList();
              dispatch({ type: "CLEAR_PROPERTY_DATA" });
            }, 1000);
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  const handlePropertyImageUpload = (event) => {
    const fileList = Array?.from(event.target.files);

    const form = new FormData();
    fileList?.map((file, index) => form.append(`files[]`, file));

    dispatch(
      allActions(form, {
        method: "post",
        endPoint: "upload_post_image",
        attempt: "EDIT_UPLOAD_PROPERTY_IMAGE_REQUEST_ATTEMPT",
        success: "EDIT_UPLOAD_PROPERTY_IMAGE_REQUEST_SUCCESS",
        failure: "EDIT_UPLOAD_PROPERTY_IMAGE_REQUEST_FAILURE",

        saveBearerToken: false,
        multipartFormData: true,
        successInternalState: (res) => {
          setImag((prev) => [
            ...prev,
            ...res?.source?.map((item) => {
              return {
                image_name: item?.image_name,
                thumbnail: item?.image_thumnail_name,
              };
            }),
          ]);
        },
        failureInternalState: () => {},
      })
    );
  };

  const handleRemovePropertyImage = (img, index) => {
    dispatch(
      allActions(
        { image_name: img?.image_name },
        {
          method: "post",
          endPoint: "remove_image",
          attempt: "PF_DELETE_IMAGE_REQUEST_ATTEMPT",
          success: "PF_DELETE_IMAGE_REQUEST_SUCCESS",
          failure: "PF_DELETE_IMAGE_REQUEST_FAILURE",

          saveBearerToken: false,
          multipartFormData: false,
          successInternalState: () => {
            message.success("Image Deleted Successfully");
            setImag(postImages.filter((img, indx) => index !== indx));
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  const handleFileUpload = (event) => {
    const fileList = Array?.from(event.target.files);
    const form = new FormData();
    fileList?.map((file, index) => form.append(`files[]`, file));

    dispatch(
      allActions(form, {
        method: "post",
        endPoint: "upload_post_image",
        attempt: "UPLOAD_ROOM_IMAGE_REQUEST_ATTEMPT",
        success: "UPLOAD__ROOM_IMAGE_REQUEST_SUCCESS",
        failure: "UPLOAD__ROOM_IMAGE_REQUEST_FAILURE",

        saveBearerToken: false,
        multipartFormData: true,
        successInternalState: (res) => {
          setImagList((prev) => [
            ...prev,
            ...res?.source?.map((item) => {
              return {
                image_name: item?.image_name,
                thumbnail: item?.image_thumnail_name,
              };
            }),
          ]);
        },
        failureInternalState: () => {},
      })
    );
  };

  const handleRemoveImage = (img, index) => {
    dispatch(
      allActions(
        { image_name: img?.image_name },
        {
          method: "post",
          endPoint: "remove_image",
          attempt: "PF_DELETE_IMAGE_REQUEST_ATTEMPT",
          success: "PF_DELETE_IMAGE_REQUEST_SUCCESS",
          failure: "PF_DELETE_IMAGE_REQUEST_FAILURE",

          saveBearerToken: false,
          multipartFormData: false,
          successInternalState: () => {
            message.success("Image Deleted Successfully");
            setImagList(floor_plans.filter((img, indx) => index !== indx));
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  const handleAddAgent = () => {
    setAgentDrawer(true);
  };

  //media file
  const handleRemoveMediaFile = (index) => {
    setMediaFile((prev) => prev.filter((_, ind) => index !== ind));
  };

  const handleMediatypeChange = (value) => {
    value && setIsChoosenMedia(true);
  };

  const handleAddMediaType = () => {
    var pattern = /^\s*$/;
    const temp = form.getFieldValue("txt_media_type");
    if (!temp?.link_url || pattern.test(temp?.link_url)) {
      message.warning("Please Enter the Media Url");
    } else {
      setMediaFile((prev) => {
        return [...prev, temp];
      });
      form.resetFields(["txt_media_type"]);
      setIsChoosenMedia((prev) => !prev);
    }
  };

  return (
    <Drawer
      title="Edit Ad"
      placement="right"
      onClose={onClose}
      open={editOpenModal}
      width={drawerWidth}
    >
      {(postadloading || editimageloading || dimageloading || roomloading) && (
        <MainLoader />
      )}
      {postEditFormData?.form_fields?.static_fields?.length > 0 && (
        <Form
          initialValues={{
            address: postEditFormData?.post?.address,
            price_type: postEditFormData?.post?.price_type,
            ad_price: postEditFormData?.post?.price,
            to_ad_price: postEditFormData?.post?.to_price,
            Property_condition: postEditFormData?.post?.property_condition,
            sell_type: postEditFormData?.post?.sell_type,
            show_price: postEditFormData?.post?.show_price,
            property_type: postEditFormData?.post?.property_type,
            no_room: postEditFormData?.post?.no_room,
            no_of_car_space: postEditFormData?.post?.no_of_car_space,
            description: postEditFormData?.post?.description,
            house_area_type: postEditFormData?.post?.house_size_type,
            land_area_type: postEditFormData?.post?.land_size_type,
            houseAreaType:
              postEditFormData?.post?.house_size_type === "Ropany"
                ? {
                    txt_house_ropani_ropani:
                      postEditFormData?.post?.house_ropany,
                    txt_house_ropani_aana: postEditFormData?.post?.house_aana,
                    txt_house_ropani_paisa: postEditFormData?.post?.house_paisa,
                    txt_house_ropani_daam: postEditFormData?.post?.house_daam,
                  }
                : postEditFormData?.post?.house_size_type === "Bigha"
                ? {
                    txt_house_bigha_bigha: postEditFormData?.post?.house_bigha,
                    txt_house_bigha_katha: postEditFormData?.post?.house_katha,
                    txt_house_bigha_dhur: postEditFormData?.post?.house_dhur,
                  }
                : { txt_house_sqft_sqft: postEditFormData?.post?.house_area },
            landAreaType:
              postEditFormData?.post?.land_size_type === "Ropany"
                ? {
                    txt_land_ropani_ropani: postEditFormData?.post?.land_ropany,
                    txt_land_ropani_aana: postEditFormData?.post?.land_aana,
                    txt_land_ropani_paisa: postEditFormData?.post?.land_paisa,
                    txt_land_ropani_daam: postEditFormData?.post?.land_daam,
                  }
                : postEditFormData?.post?.land_size_type === "Bigha"
                ? {
                    txt_land_bigha_bigha: postEditFormData?.post?.land_bigha,
                    txt_land_bigha_katha: postEditFormData?.post?.land_katha,
                    txt_land_bigha_dhur: postEditFormData?.post?.land_dhur,
                  }
                : { txt_land_sqft_sqft: postEditFormData?.post?.area },
            security_bound: postEditFormData?.post?.security_bound,

            Parking: postEditFormData?.post?.parking_type,
            Number_of_Bathrooms: postEditFormData?.post?.no_of_bathroom,
            property_location: postEditFormData?.post?.ads_data?.location,
            ticket_start_date:
              postEditFormData?.post?.start_date &&
              moment(new Date(postEditFormData?.post?.start_date)),
            inspection_date_times: postEditFormData?.inspection_times?.map(
              (data, index) => ({
                ...data,
                date: moment(data?.inspection_date, "DD-MM-YYYY"),
                start_time: moment(data.start_time, "h:mm A"),
                end_time: moment(data.end_time, "h:mm A"),
              })
            ),

            agents: postEditFormData?.real_estate?.selected_agents?.map(
              (agent) => agent?.agent_id
            ),

            first_name: realestatedata?.first_name,
            last_name: realestatedata?.last_name,
            email: realestatedata?.user_email,
            phone: realestatedata?.user_mobile,
            location: realestatedata?.street_address,

            price_type2: postEditFormData?.post?.price_type2,
          }}
          form={form}
          layout="vertical"
          onFinish={handleUpdateForm}
          style={{ margin: "0 2rem" }}
        >
          <div style={{ marginTop: "1rem" }} className="two--row--container">
            <Form.Item
              className="two--row--child--container"
              label="Property Address"
              name="address"
              rules={[
                {
                  required: true,
                  message: "Property Address is required",
                },
              ]}
            >
              <Autocomplete
                apiKey="AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU"
                style={{
                  width: "100%",
                  outline: "none",
                  height: "2.5rem",
                  border: "1px solid #D8D9DA",
                  padding: "0 0.5rem",
                }}
                onPlaceSelected={(place) => handleSetAddress(place)}
                options={{
                  types: ["address"],
                  componentRestrictions: { country: "au" },
                }}
              />
            </Form.Item>

            <Form.Item
              className="two--row--child--container"
              label="Property postcode"
              name="property_postcode"
              rules={[
                {
                  required: true,
                  message: "Property postcode is required",
                },
              ]}
            >
              <InputNumber
                size="large"
                placeholder="Property postcode"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </div>
          <div className="two--row--container">
            <Form.Item
              className="two--row--child--container"
              label="Property State"
              name="property_state"
              rules={[
                {
                  required: true,
                  message: "Property state is required",
                },
              ]}
            >
              <Input size="large" placeholder="Property State" />
            </Form.Item>
            <Form.Item
              className="two--row--child--container"
              label="Property Location"
              name="property_location"
              // rules={[
              //   {
              //     required: true,
              //     message: "Property Location is required",
              //   },
              // ]}
            >
              <Select
                size="large"
                showSearch
                placeholder="Select Property Location"
                options={propertyLocationList}
              />
            </Form.Item>
          </div>
          <div className="two--row--container">
            <Form.Item
              className="two--row--child--container"
              label="Property District"
              name="property_district"
              rules={[
                {
                  required: true,
                  message: "Property District is required",
                },
              ]}
            >
              <Input size="large" placeholder="Property District" />
            </Form.Item>
            <Form.Item
              className="two--row--child--container"
              label="Property Street Name"
              name="property_street_name"
              rules={[
                {
                  required: true,
                  message: "Property street name is required",
                },
              ]}
            >
              <Input size="large" placeholder="Property Street Name" />
            </Form.Item>
          </div>

          <h4>Show Detail</h4>
          <div style={{ marginTop: "1rem" }} className="two--row--container">
            {static_fields?.map((item) => {
              if (item?.attr?.label === "Property condition") {
                return (
                  <Form.Item
                    name={item?.attr?.name}
                    label={item?.attr?.label}
                    rules={[
                      {
                        required: true,
                        message: "Select display value",
                      },
                    ]}
                    className="two--row--child--container"
                  >
                    <Radio.Group size="large">
                      {item?.childs?.map((itm) => (
                        <Radio value={itm?.value}>{itm?.label}</Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                );
              }
              return null;
            })}

            {static_fields?.map((item) => {
              if (item?.attr?.label === "Sell type") {
                return (
                  <Form.Item
                    className="two--row--child--container"
                    rules={[
                      {
                        required: true,
                        message: "Sell type is required",
                      },
                    ]}
                    label={item?.attr?.label}
                    name={item?.attr?.name}
                  >
                    <Select
                      size="large"
                      placeholder="Select sell type"
                      options={[
                        ...item?.childs?.map((itm) => {
                          return {
                            value: itm?.value,
                            label: itm?.label,
                          };
                        }),
                      ]}
                    ></Select>
                  </Form.Item>
                );
              }
              return null;
            })}
          </div>

          {static_fields?.map((item) => {
            if (item?.attr?.label === "Price Type") {
              return (
                <Form.Item
                  name={item?.attr?.name}
                  label={item?.attr?.label}
                  rules={[
                    {
                      required: true,
                      message: "Sell type is required",
                    },
                  ]}
                  onChange={handleChangePriceType}
                >
                  <Radio.Group>
                    {item?.childs?.map((itm, index) => (
                      <Radio value={itm?.value}>{itm?.label}</Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              );
            }
            return null;
          })}

          <Space
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.2rem",
              alignItems: "center",
            }}
          >
            {(isPriceType === "1" || isPriceType === "2") && (
              <Form.Item name="ad_price">
                <InputNumber
                  type="number"
                  size="large"
                  placeholder={isPriceType === "1" ? "Price" : "From Price"}
                  addonAfter="NR"
                />
              </Form.Item>
            )}
            {isPriceType === "2" && (
              <Form.Item name="to_ad_price">
                <InputNumber
                  size="large"
                  type="number"
                  addonAfter="NR"
                  placeholder="To Price"
                />
              </Form.Item>
            )}
            {static_fields?.map((item) => {
              if (item?.attr?.name === "price_type2") {
                return (
                  <Form.Item name={item?.attr?.name}>
                    <Select
                      placeholder="Select Price Duration"
                      size="large"
                      options={[
                        ...item?.childs?.map((item) => {
                          return {
                            label: item?.label,
                            value: item?.value,
                          };
                        }),
                      ]}
                    ></Select>
                  </Form.Item>
                );
              }
              return null;
            })}
          </Space>
          <div style={{ marginTop: "1rem" }} className="two--row--container">
            <Form.Item
              name="show_price"
              label="Show Price"
              className="two--row--child--container"
              rules={[
                {
                  required: true,
                  message: "Show price is required",
                },
              ]}
              onChange={handleShowPriceChange}
            >
              <Radio.Group>
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </Form.Item>
            {static_fields?.map((item) => {
              if (item?.attr?.name === "security_bound") {
                return (
                  <Form.Item
                    className="two--row--child--container"
                    label={item?.attr?.label}
                    name={item?.attr?.name}
                  >
                    <Input size="large" placeholder="Security Bond" />
                  </Form.Item>
                );
              }
              return null;
            })}
          </div>

          <div className="two--row--container">
            {static_fields?.map((item) => {
              if (item?.attr?.label === "Property type") {
                return (
                  <Form.Item
                    className="two--row--child--container"
                    rules={[
                      {
                        required: true,
                        message: "Property type is required",
                      },
                    ]}
                    label={item?.attr?.label}
                    name={item?.attr?.name}
                  >
                    <Select
                      size="large"
                      placeholder="Select sell type"
                      options={[
                        ...item?.childs?.map((itm) => {
                          return {
                            value: itm?.value,
                            label: itm?.label,
                          };
                        }),
                      ]}
                    ></Select>
                  </Form.Item>
                );
              }
            })}

            {static_fields?.map((item) => {
              if (item?.attr?.label === "Available from") {
                return (
                  <Form.Item
                    label={item?.attr?.label}
                    name={item?.attr?.name}
                    rules={[
                      {
                        required: item?.is_required,
                        message: "Avaialbe From is required",
                      },
                    ]}
                    className="two--row--child--container"
                  >
                    <DatePicker
                      size="large"
                      disabledDate={(cd) => {
                        return cd < moment().subtract(1, "days");
                      }}
                      name={item?.attr?.name}
                      type="date"
                      format="DD--MM--YYYY"
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                );
              }
              return null;
            })}
          </div>

          <div className="two--row--container">
            {static_fields?.map((item) => {
              if (item?.attr?.name === "house_area_type") {
                return (
                  <div className="two--row--child--container">
                    <Form.Item
                      name={item?.attr?.name}
                      label={item?.attr?.label}
                      style={{ marginBottom: "0.5rem" }}
                    >
                      <Select
                        onChange={(value) => setHouseAreaType(value)}
                        size="large"
                        placeholder="Select House Area Type"
                        options={[
                          ...item?.childs?.map((itm) => {
                            return {
                              label: itm?.label,
                              value: itm?.value,
                            };
                          }),
                        ]}
                      />
                    </Form.Item>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "0.5rem",
                        width: "100%",
                        marginBottom: "1rem",
                      }}
                    >
                      {item?.childs
                        ?.filter(
                          (houseType) => houseType?.value === houseAreaType
                        )[0]
                        ?.values?.map((itm) => (
                          <Form.Item
                            style={{ margin: 0 }}
                            label={itm?.label}
                            name={["houseAreaType", itm?.name]}
                          >
                            <InputNumber
                              size="large"
                              style={{ width: "100%" }}
                              defaultValue={0}
                            />
                          </Form.Item>
                        ))}
                    </div>
                  </div>
                );
              }
            })}

            {static_fields?.map((item) => {
              if (item?.attr?.name === "land_area_type") {
                return (
                  <div className="two--row--child--container">
                    <Form.Item
                      name={item?.attr?.name}
                      label={item?.attr?.label}
                      style={{ marginBottom: "0.5rem" }}
                    >
                      <Select
                        onChange={(value) => setLandAreaType(value)}
                        size="large"
                        placeholder="Select Land Area Type"
                        options={[
                          ...item?.childs?.map((itm) => {
                            return {
                              label: itm?.label,
                              value: itm?.value,
                            };
                          }),
                        ]}
                      />
                    </Form.Item>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "0.5rem",
                        width: "100%",
                        marginBottom: "1rem",
                      }}
                    >
                      {item?.childs
                        ?.filter(
                          (landType) => landType?.value === landAreaType
                        )[0]
                        ?.values?.map((itm) => (
                          <Form.Item
                            style={{ margin: 0 }}
                            label={itm?.label}
                            name={["landAreaType", itm?.name]}
                          >
                            <InputNumber
                              size="large"
                              style={{ width: "100%" }}
                              defaultValue={0}
                            />
                          </Form.Item>
                        ))}
                    </div>
                  </div>
                );
              }
            })}
          </div>

          <div className="two--row--container">
            {static_fields?.map((item) => {
              if (item?.attr?.name === "area") {
                return (
                  <Form.Item
                    className="two--row--child--container"
                    name={item?.attr?.name}
                    label={item?.attr?.label}
                  >
                    <InputNumber
                      type="number"
                      placeholder="M * M"
                      style={{ width: "100%" }}
                      min={0}
                    />
                  </Form.Item>
                );
              } else if (item?.attr?.name === "house_area") {
                return (
                  <Form.Item
                    className="two--row--child--container"
                    name={item?.attr?.name}
                    label={item?.attr?.label}
                  >
                    <InputNumber
                      type="number"
                      placeholder="M * M"
                      style={{ width: "100%" }}
                      min={0}
                    />
                  </Form.Item>
                );
              }
              return null;
            })}
          </div>
          <div className="two--row--container">
            {static_fields?.map((item) => {
              if (item?.attr?.name === "no_room") {
                return (
                  <Form.Item
                    className="two--row--child--container"
                    label={item?.attr?.label}
                    name={item?.attr?.name}
                  >
                    <Select
                      size="large"
                      placeholder="Select Room Number"
                      options={[
                        ...item?.childs?.map((itm) => {
                          return {
                            value: itm?.value,
                            label: itm?.label,
                          };
                        }),
                      ]}
                    ></Select>
                  </Form.Item>
                );
              } else if (item?.attr?.name === "Number_of_Bathrooms") {
                return (
                  <Form.Item
                    className="two--row--child--container"
                    label={item?.attr?.label}
                    name={item?.attr?.name}
                  >
                    <Select
                      size="large"
                      placeholder="Select Bathroom Number"
                      options={[
                        ...item?.childs?.map((itm) => {
                          return {
                            value: itm?.value,
                            label: itm?.label,
                          };
                        }),
                      ]}
                    ></Select>
                  </Form.Item>
                );
              }
              return null;
            })}
          </div>
          <div className="two--row--container">
            {static_fields?.map((item) => {
              if (item?.attr?.name === "no_of_car_space") {
                return (
                  <Form.Item
                    className="two--row--child--container"
                    label={item?.attr?.label}
                    name={item?.attr?.name}
                  >
                    <Select
                      placeholder="Select car space"
                      size="large"
                      options={[
                        ...item?.childs?.map((itm) => {
                          return {
                            value: itm?.value,
                            label: itm?.label,
                          };
                        }),
                      ]}
                    ></Select>
                  </Form.Item>
                );
              } else if (item?.attr?.name === "Parking") {
                return (
                  <Form.Item
                    className="two--row--child--container"
                    label={item?.attr?.label}
                    name={item?.attr?.name}
                  >
                    <Select
                      size="large"
                      placeholder="Select parking type"
                      options={[
                        ...static_fields[15]?.childs?.map((itm) => {
                          return {
                            value: itm?.value,
                            label: itm?.label,
                          };
                        }),
                      ]}
                    ></Select>
                  </Form.Item>
                );
              }
              return null;
            })}
          </div>
          <div className="two--row--container">
            <Form.Item
              className="two--row--child--container"
              name="title"
              label="Ad Title"
              rules={[
                {
                  required: true,
                  message: "Ad Title is required",
                },
              ]}
            >
              <Input size="large" placeholder="Enter Ad Title" />
            </Form.Item>

            <Form.Item
              className="two--row--child--container"
              name="description"
              label="Ad Description"
            >
              <TextArea placeholder="e.g abc" rows={4} />
            </Form.Item>
          </div>
          {/* <div className="two--row--container">
            {static_fields?.map((item) => {
              if (item?.attr?.name === "post_indoor_features") {
                return (
                  <Form.Item
                    className="two--row--child--container"
                    name={item?.attr?.name}
                    label={item?.attr?.label}
                  >
                    <CheckboxGroup
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gridGap: "1rem",
                        padding: "1rem",
                        alignItems: "center",
                        backgroundColor: "white",
                        borderRadius: "0.5rem",
                        maxHeight: "16rem",
                        overflowY: "scroll",
                        border: "1px solid #DBDFEA",
                      }}
                      options={[
                        ...item?.childs?.map((itm) => {
                          return {
                            label: (
                              <span
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "0.5rem",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  style={{
                                    width: "2rem",
                                    height: "2rem",
                                    objectFit: "contain",
                                  }}
                                  src={itm?.image_name}
                                  alt=""
                                />{" "}
                                {itm?.label}
                              </span>
                            ),
                            value: itm?.value,
                          };
                        }),
                      ]}
                    />
                  </Form.Item>
                );
              } else if (item?.attr?.name === "post_outdoor_features") {
                return (
                  <Form.Item
                    className="two--row--child--container"
                    name={item?.attr?.name}
                    label={item?.attr?.label}
                  >
                    <CheckboxGroup
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "1rem",
                        padding: "1rem",
                        alignItems: "center",
                        backgroundColor: "white",
                        borderRadius: "0.5rem",
                        height: "100%",
                        maxHeight: "16rem",
                        overflowY: "scroll",
                        border: "1px solid #DBDFEA",
                      }}
                      options={[
                        ...item?.childs?.map((itm) => {
                          return {
                            label: (
                              <span
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "0.5rem",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  style={{
                                    width: "2rem",
                                    height: "2rem",
                                    objectFit: "contain",
                                  }}
                                  src={itm?.image_name}
                                  alt=""
                                />{" "}
                                {itm?.label}
                              </span>
                            ),
                            value: itm?.value,
                          };
                        }),
                      ]}
                      className="two-column-checkbox"
                    />
                  </Form.Item>
                );
              }
              return null;
            })}
          </div> */}

          <Form.Item label="Inspection Time">
            <Form.List name="inspection_date_times">
              {(fields, { add, remove }) => {
                return (
                  <>
                    <Button
                      style={{ marginBottom: "1rem" }}
                      onClick={() => add()}
                    >
                      <PlusOutlined /> Inspection
                    </Button>
                    {fields.map((field, index) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                        key={field.key}
                      >
                        <Space>
                          <Form.Item
                            label="Date"
                            name={[field.name, "date"]}
                            fieldKey={[field.fieldKey, "date"]}
                          >
                            <DatePicker
                              size="large"
                              disabledDate={(cd) => {
                                return cd < moment().subtract(1, "days");
                              }}
                              format="DD--MM--YYYY"
                            />
                          </Form.Item>
                          <Form.Item
                            label="Start Time"
                            name={[field.name, "start_time"]}
                            fieldKey={[field.fieldKey, "start_time"]}
                          >
                            <TimePicker
                              size="large"
                              placeholder="Start Time"
                              format="h:mm A"
                            />
                          </Form.Item>
                          <Form.Item
                            label="End Time"
                            name={[field.name, "end_time"]}
                            fieldKey={[field.fieldKey, "end_time"]}
                          >
                            <TimePicker
                              size="large"
                              placeholder="End Time"
                              format="h:mm A"
                            />
                          </Form.Item>
                        </Space>

                        <DeleteOutlined
                          onClick={() => remove(field.name)}
                          style={{
                            fontSize: "1.5rem",
                            color: "#FF8400",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    ))}
                  </>
                );
              }}
            </Form.List>
          </Form.Item>

          <>
            <AddAgentDrawer
              agentDrawer={agentDrawer}
              setAgentDrawer={setAgentDrawer}
            />

            <Form.Item
              style={{
                marginTop: "1rem",
              }}
              label="*Agent"
            >
              <Button style={{ marginBottom: "1rem" }} onClick={handleAddAgent}>
                Add Agent
              </Button>
              {static_fields?.map((item) => {
                if (item?.attr?.name === "agents") {
                  return (
                    <Form.Item
                      name={item?.attr?.name}
                      rules={[
                        {
                          required: true,
                          message: "Agent is required",
                        },
                      ]}
                    >
                      <CheckboxGroup
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr 1fr 1fr",
                          gridGap: "1rem",
                          padding: "1rem",
                          backgroundColor: "white",
                          borderRadius: "0.5rem",
                          maxHeight: "10rem",
                          overflowY: "scroll",
                          alignItems: "center",
                          border: "1px solid #DBDFEA",
                        }}
                        options={item?.childs?.map((data) => {
                          return {
                            label: data?.label,
                            value: data?.value,
                          };
                        })}
                        className="two-column-checkbox"
                      />
                    </Form.Item>
                  );
                }
                return null;
              })}
            </Form.Item>

            <div className="two--row--container">
              <Form.Item
                className="two--row--child--container"
                label="Select Property Images"
              >
                {postImages?.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      width: "fit-content",
                    }}
                  >
                    <FileImageOutlined
                      style={{
                        fontSize: "8rem",
                        color: "gray",
                        cursor: "pointer",
                        padding: "1rem",
                        borderRadius: "0.5rem",
                        backgroundColor: "white",
                      }}
                      className="post--ad--image"
                    />
                    <Button
                      onClick={() => imageRef?.current?.click()}
                      style={{
                        backgroundColor: "transparent",
                        borderRadius: "3rem",
                        fontWeight: "800",
                        border: "3px solid #F99417",
                        color: "#F99417",
                      }}
                      className="post--ad--image--upload"
                    >
                      UpLoad
                    </Button>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: "0.5rem",
                      border: "1px solid #DBDFEA",
                      background: "white",
                      padding: "0.5rem",
                      maxHeight: "20rem",
                      overflowY: "scroll",
                      borderRadius: "0.5rem",
                    }}
                  >
                    {postImages?.map((img, index) => (
                      <div key={img} className="image--container">
                        <img
                          className="floor--image"
                          style={{
                            maxHeight: "8rem",
                            height: "auto",
                            width: "100%",
                            objectFit: "cover",
                            border: "1px solid #80808045",
                            cursor: "pointer",
                          }}
                          src={img?.image_name}
                          alt="img"
                        />
                        <DeleteOutlined
                          onClick={() => handleRemovePropertyImage(img, index)}
                          className="delete--floor--image"
                        />
                      </div>
                    ))}
                  </div>
                )}
                <Form.Item
                  style={{ marginTop: "-2rem" }}
                  name="imageProperty"
                  rules={[
                    {
                      required: postImages?.length > 0 ? false : true,
                      message: "Property image is required",
                    },
                  ]}
                >
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg, .gif"
                    multiple
                    style={{ display: "none" }}
                    ref={imageRef}
                    onChange={handlePropertyImageUpload}
                  />
                </Form.Item>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "1rem 0",
                    gap: "0.5rem",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <Alert
                    className="alert--message"
                    banner
                    message={
                      <Marquee pauseOnHover gradient={false}>
                        Image must be in JPG, PNG, or GIF formats.
                      </Marquee>
                    }
                  />
                  {postImages?.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "1rem",
                      }}
                    >
                      <Button onClick={() => imageRef.current.click()}>
                        Upload
                      </Button>
                    </div>
                  )}{" "}
                </div>
              </Form.Item>
              <Form.Item
                className="two--row--child--container"
                label="Add Floor Plan Image"
              >
                {floor_plans.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      width: "fit-content",
                    }}
                  >
                    <FileImageOutlined
                      style={{
                        fontSize: "8rem",
                        color: "gray",
                        cursor: "pointer",
                        padding: "1rem",
                        borderRadius: "0.5rem",
                        backgroundColor: "white",
                      }}
                      className="post--ad--image"
                    />
                    <Button
                      onClick={() => imageListRef.current.click()}
                      style={{
                        backgroundColor: "transparent",
                        borderRadius: "3rem",
                        fontWeight: "800",
                        border: "3px solid #F99417",
                        color: "#F99417",
                      }}
                      className="post--ad--image--upload"
                    >
                      UpLoad
                    </Button>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      borderRadius: "0.5rem",
                      gap: "0.5rem",
                      background: "white",
                      padding: "0.5rem",
                      maxHeight: "20rem",
                      overflowY: "scroll",
                      border: "1px solid #DBDFEA",
                    }}
                  >
                    {floor_plans.map((img, index) => (
                      <div key={img} className="image--container">
                        <img
                          className="floor--image"
                          style={{
                            maxHeight: "8rem",
                            height: "auto",
                            cursor: "pointer",
                            objectFit: "cover",
                            width: "100%",
                          }}
                          src={img?.image_name}
                          alt="img"
                        />
                        <DeleteOutlined
                          onClick={() => handleRemoveImage(img, index)}
                          className="delete--floor--image"
                        />
                      </div>
                    ))}
                  </div>
                )}

                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  multiple
                  style={{ display: "none" }}
                  ref={imageListRef}
                  onChange={handleFileUpload}
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "1rem 0",
                    gap: "0.5rem",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <Alert
                    className="alert--message"
                    banner
                    message={
                      <Marquee pauseOnHover gradient={false}>
                        Image must be in JPG, PNG, or GIF formats.
                      </Marquee>
                    }
                  />
                  {floor_plans.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "1rem",
                      }}
                    >
                      <Button onClick={() => imageListRef.current.click()}>
                        Upload
                      </Button>
                    </div>
                  )}{" "}
                </div>
              </Form.Item>
            </div>
          </>

          <Form.Item label="Add Media">
            <Space.Compact
              size="large"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                margin: 0,
              }}
            >
              <Form.Item
                name={["txt_media_type", "media_type"]}
                style={{ width: "25%", margin: 0 }}
              >
                <Select
                  size="large"
                  onChange={handleMediatypeChange}
                  placeholder="Select Media Type"
                  options={Object.keys(mediaType)?.map((item) => {
                    return {
                      label: item,
                      value: item,
                    };
                  })}
                ></Select>
              </Form.Item>
              <Form.Item
                style={{ width: "100%", margin: 0 }}
                name={["txt_media_type", "link_url"]}
                rules={[
                  isChoosenMedia === true && {
                    required: true,
                    message: "Please Select the Media Type",
                  },
                  isChoosenMedia === true && {
                    type: "url",
                    message: "Please enter a valid URL",
                  },
                ]}
              >
                <Input
                  size="large"
                  readOnly={!isChoosenMedia}
                  style={{ minWidth: "50%" }}
                  placeholder="Enter Link Url"
                />
              </Form.Item>
              <PlusCircleOutlined
                style={{
                  fontSize: "2rem",
                  marginLeft: "0.5rem",
                  color: "#3a6390",
                }}
                onClick={handleAddMediaType}
              />
            </Space.Compact>
            {mediaFile?.length > 0 && (
              <Table
                style={{ marginTop: "0.5rem" }}
                scroll={{ y: 200 }}
                dataSource={mediaFile}
                columns={columns}
                pagination={false}
              />
            )}
          </Form.Item>

          <PostAdForm3 form={form} isLarge={isLarge} />
          <Button className="button--modal--primary" htmlType="submit">
            Update Ad
          </Button>
        </Form>
      )}

      {isPreviewVideo && (
        <PreviewVideo
          videoUrl={videoUrl}
          isPreviewVideo={isPreviewVideo}
          setIsPrevieVideo={setIsPrevieVideo}
        />
      )}
    </Drawer>
  );
};

export default connect(mapStateToProps)(PostEditModal);

export const PreviewVideo = ({
  videoUrl,
  setIsPrevieVideo,
  isPreviewVideo,
}) => {
  const handleOk = () => {
    setIsPrevieVideo(false);
  };

  const handleCancel = () => {
    setIsPrevieVideo(false);
  };
  return (
    <Modal
      open={isPreviewVideo}
      onOk={handleOk}
      width={640}
      onCancel={handleCancel}
      footer={null}
      closable={false}
      className="custom-modal"
    >
      <iframe
        width="640"
        height="360"
        src={videoUrl}
        title="YouTube Video"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </Modal>
  );
};
